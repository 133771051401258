import { useIsMobile, useScrollLock } from '@patrianna/shared-hooks'
import { IconButton, Modal, Show } from '@patrianna/core-components'
import cn from 'classnames'
import classes from './RewardsDrawer.module.scss'
import type { RewardsDrawerProps } from './RewardsDrawer.types'
import DialogEmptyContent from './components/DialogEmptyContent'
import RewardsList from './components/RewardsList'
import { useAppSelector } from 'store/hooks'
import { getFreeSpinsLoadingSelector, getFreeSpinsSelector } from 'store/modules/freeSpins/selectors'
import LinearProgress from './components/LinearProgress'
import { useDrawerAnimation } from './hooks/useRewardsDrawer'

export default function RewardsDrawer({ open, onClose }: RewardsDrawerProps) {
  useScrollLock(open)
  const isMobile = useIsMobile()
  const { animate, handleClose } = useDrawerAnimation({ onClose })
  const freeSpinsLoading = useAppSelector(getFreeSpinsLoadingSelector)
  const freeSpins = useAppSelector(getFreeSpinsSelector)

  const haveRewards = Boolean(freeSpins.length)

  return (
    <Modal open={open} onClose={handleClose}>
      <section
        role='dialog'
        className={cn('mt-paper', 'mt-drawer-paper', classes.drawer, {
          [classes.drawerDesktop]: !isMobile,
          [classes.drawerMobile]: isMobile,
          [classes.drawerFadeIn]: animate,
          [classes.drawerFadeOut]: !animate,
          [classes.loading]: freeSpinsLoading,
        })}
      >
        <Show when={freeSpinsLoading}>
          <LinearProgress className={classes.linearProgress} />
        </Show>

        <Show when={isMobile}>
          <header className={classes.drawerHeader}>
            <img src={`${process.env.IMG_ORIGIN}/logo.png`} height={32} alt='Jackpota' />

            <IconButton className={classes.closeBtn} onClick={handleClose} data-test='modal-back-button'>
              <img src={`${process.env.IMG_ORIGIN}/login/close_grey.svg`} alt='close icon' width={12} height={12} />
            </IconButton>
          </header>
        </Show>

        <div
          className={cn(classes.drawerContent, {
            [classes.drawerContentNonItems]: !haveRewards,
          })}
        >
          <Show when={!haveRewards}>
            <DialogEmptyContent />
          </Show>

          <Show when={haveRewards}>
            <RewardsList freeSpins={freeSpins} />
          </Show>
        </div>
      </section>
    </Modal>
  )
}
