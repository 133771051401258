/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $GetRewardTemplateResponse = {
    properties: {
        code: {
            type: 'string',
        },
        brandId: {
            type: 'number',
            format: 'int32',
        },
        name: {
            type: 'string',
        },
        displayName: {
            type: 'string',
        },
        displayNameShort: {
            type: 'string',
        },
        active: {
            type: 'boolean',
        },
        test: {
            type: 'boolean',
        },
        available: {
            type: 'boolean',
        },
        created: {
            type: 'string',
        },
        updated: {
            type: 'string',
        },
        miniGameTypeCode: {
            type: 'string',
        },
        miniGameTypeName: {
            type: 'string',
        },
        miniGameThemeCode: {
            type: 'string',
        },
        prizeTableCode: {
            type: 'string',
        },
        iconSmallClosed: {
            type: 'string',
        },
        iconLargeClosed: {
            type: 'string',
        },
        iconSmallOpen: {
            type: 'string',
        },
        iconLargeOpen: {
            type: 'string',
        },
        shopIconSmall: {
            type: 'string',
        },
        shopIconLarge: {
            type: 'string',
        },
        timeLimited: {
            type: 'boolean',
        },
        availableFrom: {
            type: 'string',
        },
        availableTo: {
            type: 'string',
        },
        expiresAfterDays: {
            type: 'number',
            format: 'int32',
        },
        limitPerDay: {
            type: 'number',
            format: 'int64',
        },
        limitTotal: {
            type: 'number',
            format: 'int64',
        },
        termUrl: {
            type: 'string',
        },
        displayTagline: {
            type: 'string',
        },
        placements: {
            type: 'array',
            contains: {
                type: 'PlacementResponse',
            },
        },
    },
} as const;
