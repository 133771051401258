import cn from 'classnames'
import type { MenuRewardsBadgeProps } from './MenuRewardsBadge.types'
import { CircleLoader, Show } from '@patrianna/core-components'
import { OPTICAL_SHIFT_THRESHOLD } from './MenuRewardsBadge.constants'

import classes from './MenuRewardsBadge.module.scss'

export default function MenuRewardsBadge({ rewardsCount, loading }: MenuRewardsBadgeProps) {
  if (loading) {
    return <CircleLoader thickness={2} className={classes.loader} />
  }

  return (
    <Show when={rewardsCount > 0}>
      <span
        className={cn(classes.badge, {
          [classes.opticalCenter]: rewardsCount >= OPTICAL_SHIFT_THRESHOLD,
        })}
      >
        {rewardsCount}
      </span>
    </Show>
  )
}
