import { useRouter } from 'app/context/navigation'
import { useIsMobile } from '@patrianna/shared-hooks'
import { useAppDispatch } from '@patrianna-payments/shared-store/redeem/store/hooks'
import { nextTick } from '@patrianna/shared-utils'
import { openShopHandler } from 'store/modules/shop/actions'

export const useClickHandler = () => {
  const { push } = useRouter()
  const isMobile = useIsMobile()
  const dispatch = useAppDispatch()

  return () =>
    nextTick(() =>
      dispatch(
        openShopHandler({
          isMobile,
          routerPush: push,
        })
      )
    )
}
