import {
  listenPurchaseNotification,
  saveOrGetPaymentProviders,
} from '@patrianna-payments/shared-store/payments/store/actions'
import { offerDeclineNotificationHandler } from '@patrianna-payments/shared-store/payments/store/middleware-actions'
import { getQuickPurchaseSupportedTypeSelector } from '@patrianna-payments/shared-store/payments/store/selectors'
import type { PaymentProviders } from '@patrianna-payments/shared-store/payments/types/api/PaymentProviders'
import { setOTPMandatory, setSkipOTP, setSkipOtpClosed } from '@patrianna-payments/shared-store/skip-otp'
import type {
  JackpotWinNotificationNew,
  NotifyResponses,
} from '@patrianna/shared-patrianna-types/websocket/notifications'
import { isBrowser } from '@patrianna/shared-utils/helpers'
import { batch } from 'react-redux'
import type { TypedThunk } from 'src/store/types'
import { logOut, logoutRequest } from 'store/modules/auth/actions'
import {
  updateInvitationBonusesNotificationListener,
  updateManualBonusesNotificationListener,
} from 'store/modules/bonusesFlow/actions'
import { updateCurrenciesBalance } from 'store/modules/currencies/actions'
import { closeAllDialogs, openDialog, removeDialogByName } from 'store/modules/dialog/actions'
import { showFSModalFlow } from 'store/modules/freeSpins/actions'
import {
  addNewFreeContributionReward,
  getAccountJackpotInfo,
  openSpinWheelDialog,
  patchJackpotTotals,
  setContibutionsJackpot,
  setJackpotUpdateInterval,
  updateFreeContributionsBalance,
} from 'store/modules/jackpots/actions'
import { prerequisitesChange } from 'store/modules/prerequisites/actions'
import { openSnackbar } from 'store/modules/snackbar/actions'
import {
  getAccountInfo,
  getAccountInfoAndUpdateVerificationData,
  setIsEmailVerified,
  setKycStatus,
  setLevelProgress,
  setUser,
  trackKYCConfirmedEvent,
} from 'store/modules/user/actions'
import ROUTES from 'temp/routes.json'

const wsNotifyHandler =
  ({ body }: { body: NotifyResponses }): TypedThunk =>
  (dispatch, getState) => {
    batch(() => {
      switch (body.type) {
        case 'BalanceChangeNotification': {
          dispatch(updateCurrenciesBalance({ currency: body.balances }))
          break
        }

        case 'PrerequisitesChangeNotification': {
          dispatch(prerequisitesChange({ prerequisites: body.prerequisites }))
          break
        }

        case 'XPLevelChangeNotification': {
          dispatch(setLevelProgress(body))
          break
        }

        case 'OfferPurchaseNotification': {
          const { isQuickPurchase, isQuickPurchaseGetCoins } = getQuickPurchaseSupportedTypeSelector(
            getState(),
            window.location.pathname
          )

          if (!(isQuickPurchase || isQuickPurchaseGetCoins)) {
            dispatch(listenPurchaseNotification(body))
          }

          break
        }

        case 'SignOutNotification': {
          dispatch(logOut())

          break
        }

        case 'RedeemMoneyNotification': {
          // [FYI]: moved to MF
          // const { type, ...rest } = body
          // dispatch(updateRedemptionList(rest))
          break
        }

        case 'KYCDocSuccessUploadNotification': {
          dispatch(removeDialogByName({ modalName: 'VERIFICATION_KYC_DIALOG' }))
          dispatch(setKycStatus({ kycStatus: 'doc_review' }))

          const query = new URLSearchParams(window?.location?.search)

          dispatch(
            openDialog({
              modalName: 'VERIFICATION_IN_PROGRESS_DIALOG',
              dialogProps: { verificationFrom: query?.get('verificationFrom') || '' },
            })
          )

          break
        }

        case 'KYCDocErrorUploadNotification': {
          dispatch(
            openSnackbar({
              message: body.reason || 'Error uploading documents',
            })
          )
          break
        }

        case 'KYCConfirmNotification': {
          dispatch(
            openSnackbar({
              message: isBrowser()
                ? // @ts-ignore
                  window.__FORM_VALIDATION_TRANSLATIONS__?.verification?.id_confirmed
                : 'Id confirmed',
              variant: 'success',
            })
          )
          dispatch(getAccountInfoAndUpdateVerificationData('id_confirmed'))
          dispatch(trackKYCConfirmedEvent('ID_Verification_Success'))
          break
        }

        case 'KYCDocConfirmNotification': {
          dispatch(
            openSnackbar({
              message: isBrowser()
                ? // @ts-ignore
                  window.__FORM_VALIDATION_TRANSLATIONS__?.verification?.document_confirmed
                : 'Document confirmed',
              variant: 'success',
            })
          )
          dispatch(getAccountInfoAndUpdateVerificationData('confirmed'))
          dispatch(trackKYCConfirmedEvent('POA_Verification_Success'))
          break
        }

        case 'KYCInReviewNotification': {
          dispatch(setKycStatus({ kycStatus: 'in_review' }))
          break
        }

        case 'KYCDocBlockNotification':
        case 'KYCBlockNotification': {
          dispatch(setKycStatus({ kycStatus: 'blocked' }))
          dispatch(closeAllDialogs())

          // @TODO will change to home page
          dispatch(
            logoutRequest(
              `${ROUTES.HOME}?dialogName=LOCK_USER_DIALOG&dialogProps.reason=Your account has failed verification.`
            )
          )
          break
        }

        case 'ShowSystemMessageNotification': {
          if (body.markdown) {
            dispatch(
              openSnackbar({
                message: body.markdown,
              })
            )
          }
          break
        }

        case 'AccountBlockNotification': {
          dispatch(closeAllDialogs())
          if (body?.reloginProvider) {
            dispatch(
              logoutRequest(
                `${ROUTES.HOME}?dialogName=OTP_HELLO_AGAIN_DIALOG&dialogProps.reloginProvider=${body.reloginProvider}${
                  body?.reloginEmail ? `&dialogProps.reloginEmail=${body.reloginEmail}` : ''
                }`
              )
            )
          } else {
            dispatch(openDialog({ modalName: 'LOCK_USER_DIALOG', dialogProps: { singletone: true } }))
          }
          break
        }

        case 'KYCDocDeclineNotification': {
          dispatch(setKycStatus({ kycStatus: 'doc_declined' }))
          break
        }

        case 'KYCDeclineNotification': {
          dispatch(setKycStatus({ kycStatus: 'declined' }))
          break
        }

        case 'AcceptManualBonusNotification': {
          // eslint-disable-next-line
          const { type, ...rest } = body
          dispatch(updateManualBonusesNotificationListener(rest))
          break
        }

        case 'AcceptInvitationBonusNotification': {
          // eslint-disable-next-line
          const { type, ...rest } = body
          dispatch(updateInvitationBonusesNotificationListener(rest))
          // backward compatibility
          if (rest?.account) {
            dispatch(setUser({ data: rest.account }))
          }
          break
        }

        case 'OfferRefundNotification': {
          dispatch(
            openSnackbar({
              message: 'Your purchase has been refunded: Please see email for more information.',
            })
          )
          break
        }

        case 'InsufficientBalanceNotification': {
          // @TODO don't need this notification, because don't work for all game providers
          // dispatch(showLowOnCoinsDialog())
          break
        }

        case 'OfferDeclineNotification': {
          const { isQuickPurchase, isQuickPurchaseGetCoins } = getQuickPurchaseSupportedTypeSelector(
            getState(),
            window.location.pathname
          )

          if (!(isQuickPurchase || isQuickPurchaseGetCoins)) {
            dispatch(offerDeclineNotificationHandler(body))
          }

          break
        }

        case 'EmailVerifiedNotification': {
          dispatch(setIsEmailVerified({ isVerified: true }))
          window.location.href = ROUTES.HOME
          break
        }

        case 'SkipOtpChangeNotification': {
          dispatch(setSkipOTP({ skipOtp: body?.skipOtp }))
          dispatch(setOTPMandatory({ mandatory: body?.mandatory }))
          dispatch(setSkipOtpClosed({ closed: false }))
          break
        }

        case 'JackpotOptOutNotification': {
          break
        }

        case 'JackpotWinNotification': {
          const { amount, subtype: code, jackpotId, currency, spinAmount } = body as JackpotWinNotificationNew

          // @ts-ignore
          dispatch(openSpinWheelDialog({ amount, code, jackpotId, currency, spinAmount }))
          dispatch(getAccountJackpotInfo())
          break
        }

        case 'JackpotContributionNotification': {
          dispatch(setContibutionsJackpot(body.contributions))
          break
        }

        case 'AwardJackpotAccountFreeContributionNotification': {
          // eslint-disable-next-line
          const { type, ...freeContribution } = body
          dispatch(addNewFreeContributionReward(freeContribution))

          if (!window.location.href.includes('/play')) {
            dispatch(openDialog({ modalName: 'FREE_CONTRIBUTION_DIALOG' }))
          }
          break
        }

        case 'JackpotAccountFreeContributionChangeNotification': {
          dispatch(updateFreeContributionsBalance(body.freeContributions))
          break
        }

        case 'JackpotUpdateNotification': {
          dispatch(patchJackpotTotals(body.preferences))
          dispatch(setJackpotUpdateInterval(body.updateInterval))
          break
        }

        case 'UpdatePaymentMethodsNotification': {
          const { withdrawProviders, purchaseProviders } = body as unknown as PaymentProviders

          dispatch(saveOrGetPaymentProviders({ withdrawProviders, purchaseProviders }))

          break
        }

        case 'CreateFreeSpinNotification': {
          dispatch(showFSModalFlow(body.freeSpins))

          break
        }

        case 'ModeChangeNotification': {
          dispatch(getAccountInfo(true))

          break
        }

        default: {
          if (process.env.PROFILE !== 'prod') {
            // eslint-disable-next-line
            console.log(`%c${`Unhandled notify event - ${body}`}`, 'color:Orange', body)
          }
          break
        }
      }
    })
  }

export default wsNotifyHandler
