import Skeleton from 'app/components/Skeleton'
import classes from './MenuDesktopSkeleton.module.scss'

function MenuDesktopSkeleton() {
  return (
    <section className={classes.skeleton}>
      <Skeleton length={4} />
    </section>
  )
}

export default MenuDesktopSkeleton
