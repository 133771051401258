/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $SetPurchaseLimitInfo = {
    properties: {
        threshold: {
            type: 'number',
            description: `Corresponding limit.`,
            isRequired: true,
        },
        period: {
            type: 'LimitPeriod',
            description: `Time period.`,
            isRequired: true,
        },
    },
} as const;
