import type { PropsWithChildren } from 'react'

import classes from './styles.module.scss'

export default function SpecialShopTileLabelRect({ children }: PropsWithChildren) {
  return (
    <div className={classes.root}>
      <span className={classes.text}>{children}</span>

      <svg width='70' height='41' viewBox='0 0 70 41' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M4.01786 0.914551H4.02667V3.4258H4.01786C1.88627 3.4258 0.00219727 2.89841 0.00219727 2.17018C0.00219727 1.36209 1.88787 0.914551 4.01786 0.914551Z'
          fill='url(#paint0_linear_2044_7984)'
        />
        <path
          d='M0.0012207 2.17017C0.132593 2.82723 1.87648 3.42579 4.00808 3.42579H0.0012207V2.17017Z'
          fill='#ABF504'
        />
        <path
          d='M4.01786 40.9768H4.02667V38.4656H4.01786C1.88627 38.4656 0.00219727 38.993 0.00219727 39.7212C0.00219727 40.5293 1.88787 40.9768 4.01786 40.9768Z'
          fill='url(#paint1_linear_2044_7984)'
        />
        <path
          d='M0.0012207 39.7212C0.132593 39.0641 1.87648 38.4656 4.00808 38.4656H0.0012207V39.7212Z'
          fill='#ABF504'
        />
        <path
          d='M0.0012207 3.42578H67.5875C68.3901 3.42578 69.0406 4.06782 69.0406 4.8601V37.0304C69.0406 37.8226 68.3901 38.4647 67.5875 38.4647H0.0012207V3.42656V3.42578Z'
          fill='#ABF504'
        />
        <defs>
          <linearGradient
            id='paint0_linear_2044_7984'
            x1='1.61199'
            y1='1.82773'
            x2='0.0139616'
            y2='3.43748'
            gradientUnits='userSpaceOnUse'
          >
            <stop stop-color='#649001' />
            <stop offset='1' stop-color='#85BF01' />
          </linearGradient>
          <linearGradient
            id='paint1_linear_2044_7984'
            x1='1.61199'
            y1='39.3788'
            x2='0.0139616'
            y2='40.9885'
            gradientUnits='userSpaceOnUse'
          >
            <stop stop-color='#649001' />
            <stop offset='1' stop-color='#85BF01' />
          </linearGradient>
        </defs>
      </svg>
    </div>
  )
}
