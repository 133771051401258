import { useChatBot } from '@patrianna-payments/chatbot'
import ZendeskMessaging, { closeZendeskMessagingByCommand } from '@patrianna-payments/zendesk/messaging'
import { useAppSelector } from 'src/store/hooks'
import { getLiveChatTagsSelector, getLiveChatUserInfoSelector } from 'store/modules/user/selectors'
import useShowChats, { CHAT_BOT_ID, SNIPPET_KEY } from 'hooks/useShowChats'
import useDomEvent from 'hooks/useDomEvent'
import { getPaymentDialogsOpenedSelector, getPaymentErrorExistSelector } from 'src/store/modules/dialog/selectors'
import { useIsMobile } from '@patrianna/core-components/src/context/mobileDetectionContext'
import { useEffect, useState } from 'react'
import { nextTick } from '@patrianna/shared-utils'

const messagingIframeNames = [
  'Messaging window',
  'Ventana de mensajería',
  '消息传送窗口',
  'Окно обмена сообщениями',
  'Janela de mensagens',
  'Messaging-Fenster',
  'Fenêtre de messagerie',
]
const selector = messagingIframeNames.map((name) => `iframe[name="${name}"]`).join(', ') // need for changing styles of Zendesk messaging iframe on mobile

export default function Chats() {
  const user = useAppSelector(getLiveChatUserInfoSelector)
  const liveChatTags = useAppSelector(getLiveChatTagsSelector)
  const isPaymentDialogsOpened = useAppSelector(getPaymentDialogsOpenedSelector)
  const isPaymentErrorExist = useAppSelector(getPaymentErrorExistSelector)
  const isMobile = useIsMobile()
  const isVisibleBadge = isPaymentDialogsOpened || isPaymentErrorExist
  // const departments = useAppSelector(getLiveChatDepartmentsSelector) // [FYI]: need for future

  const { isShowLiveChat, isShowChatBot, isShowLiveChatFromMenu, isShowChatBotCom } = useShowChats()

  const [isOpenMessengerFrame, setIsOpenMessengerFrame] = useState(false)

  const conversationBadgeStyles = isVisibleBadge
    ? { zIndex: 10000, background: '#f2ae3e', opacity: 1 }
    : { zIndex: -1, background: '#f2ae3e', opacity: 0 }

  useChatBot(CHAT_BOT_ID, !isShowLiveChat && isShowChatBotCom)

  const bottomBarStateHandler = (e: CustomEvent) => {
    const botContainer = document.getElementById('chatbot-chat')

    if (botContainer?.style) {
      botContainer.style.transform = `translateY(${e.detail.isBottomOpened ? '-135px' : '0'})`
    }
  }

  const onChatOpenCallback = () => setIsOpenMessengerFrame(true)

  const changeChatSizeOnPaymentFlow = () => {
    let iframe = document.querySelector(selector)

    if (iframe) {
      if (isPaymentDialogsOpened || isPaymentErrorExist) {
        iframe.classList.add('ZE_Messenger_iframe')
      } else {
        iframe.classList.remove('ZE_Messenger_iframe')
      }
    }
  }

  useDomEvent('BOTTOM_BAR_STATE', bottomBarStateHandler)

  useEffect(() => {
    if (!isPaymentDialogsOpened && !isPaymentErrorExist && isMobile) {
      closeZendeskMessagingByCommand()
    }
  }, [isPaymentDialogsOpened, isPaymentErrorExist, isMobile])

  useEffect(() => {
    nextTick(changeChatSizeOnPaymentFlow)
    // eslint-disable-next-line react-hooks-static-deps/exhaustive-deps
  }, [isPaymentDialogsOpened, isPaymentErrorExist, isOpenMessengerFrame])

  if (!isShowLiveChatFromMenu && !isShowLiveChat && !isShowChatBot && !isShowChatBotCom) {
    return null
  }

  if ((isShowLiveChat || isShowLiveChatFromMenu) && !!user?.email?.length) {
    return (
      <ZendeskMessaging
        snippetKey={SNIPPET_KEY}
        info={user}
        tags={liveChatTags}
        actionButtonProps={{ conversationBadgeStyles }}
        onChatOpenCallback={onChatOpenCallback}
      />
    )
  }
}
