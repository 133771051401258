import { createSelector } from '@reduxjs/toolkit'
import type { ReduxState } from 'src/store/types'
import { sweepstakeEnabledSelector } from 'store/modules/appConfig/selectors'
import { isLoggedInSelector } from 'store/modules/user/selectors'
import { getTodayBonus } from './utils'
// import { isTenDaysSinceLastDepositSelector, isTenDaysSinceRegistrationSelector } from '../user/selectors'
import { dailyRewardModalLastTimeShownSelector } from '../dialog/selectors'
import { BRAND_MCLUCK } from 'config/brands'
import { hasPassedTime } from '@patrianna/shared-utils'
import { getGcGeneratorEnabled } from 'store/modules/gcGenerator/selectors'
import { getDataFromSessionStorage, REWARD_CODE } from 'utils/sessionStorage'

const getDailyBonuses = (state: ReduxState) => state.bonusesFlow?.dailyBonuses
const getManualBonuses = (state: ReduxState) => state.bonusesFlow?.manualBonuses
const getInvitationBonuses = (state: ReduxState) => state.bonusesFlow?.invitationBonuses
const getWeeklyRewardBonuses = (state: ReduxState) => state.bonusesFlow?.weeklyRewardBonuses
const getShowCaptcha = (state: ReduxState) => state.bonusesFlow?.showCaptcha
const getLoadingBonuses = (state: ReduxState) => state.bonusesFlow?.loading
const getAcceptingDailyBonus = (state: ReduxState) => state.bonusesFlow?.acceptingDailyBonus
const getSocialMediaReward = (state: ReduxState) => state.bonusesFlow?.socialMediaReward
export const getDailyBonusesSelector = createSelector(getDailyBonuses, (dailyBonuses) => dailyBonuses)
export const getManualBonusesSelector = createSelector(getManualBonuses, (bonuses) => bonuses)
export const getInvitationBonusesSelector = createSelector(getInvitationBonuses, (bonuses) => bonuses)

export const getTodayBonusSelector = createSelector(getDailyBonusesSelector, (dailyBonuses) => {
  return getTodayBonus(dailyBonuses)
})

export const getWeeklyRewardBonusesSelector = createSelector(getWeeklyRewardBonuses, (weeklyRewardBonuses) => {
  return weeklyRewardBonuses
})

export const getSortedByDateDailyBonusesSelector = createSelector(getDailyBonusesSelector, (dailyBonuses) => {
  if (dailyBonuses) {
    return [...dailyBonuses].sort((a, b) => Number(new Date(a.time || a.code)) - Number(new Date(b.time || a.code)))
  }

  return []
})
export const getNextBonusTimeSelector = createSelector(
  getSortedByDateDailyBonusesSelector,
  (sortedDailyBonuses): string | null => {
    if (sortedDailyBonuses && sortedDailyBonuses?.length > 0) {
      return sortedDailyBonuses[0].time
    }

    return null
  }
)

export const getSCManualBonusesSelector = createSelector(getManualBonusesSelector, (bonuses) =>
  bonuses?.filter((el) => el?.sweepstakeAmount && (el?.goldAmount !== null || el?.goldAmount !== undefined))
)

export const getGCManualBonusesSelector = createSelector(getManualBonusesSelector, (bonuses) =>
  bonuses?.filter((el) => el?.goldAmount && !el?.sweepstakeAmount)
)

export const getTotalManualBonusesSCSelector = createSelector(getManualBonusesSelector, (bonuses): number =>
  bonuses.reduce((acc, bonus) => acc + bonus.sweepstakeAmount, 0)
)
export const getTotalManualBonusesGCSelector = createSelector(getManualBonusesSelector, (bonuses): number =>
  bonuses.reduce((acc, bonus) => acc + bonus.goldAmount, 0)
)

export const isDailyBonusAvailableSelector = createSelector(getDailyBonusesSelector, (bonuses) => {
  return Boolean(getTodayBonus(bonuses))
})

export const isManualBonusAvailableSelector = createSelector(
  getManualBonusesSelector,
  getGCManualBonusesSelector,
  sweepstakeEnabledSelector,
  (bonuses, gcBonuses, sweepstakeEnabled) => {
    return sweepstakeEnabled ? Boolean(bonuses?.length) : Boolean(gcBonuses?.length)
  }
)

export const isInvitationBonusAvailableSelector = createSelector(getInvitationBonusesSelector, (invitationBonuses) =>
  Boolean(invitationBonuses?.length)
)

export const getShowCaptchaSelector = createSelector(getShowCaptcha, (showCaptcha) => showCaptcha)

export const getLoadingBonusesSelector = createSelector(getLoadingBonuses, (loading) => loading)

export const getAcceptingDailyBonusSelector = createSelector(getAcceptingDailyBonus, (loading) => loading)

export const getSocialMediaRewardSelector = createSelector(
  getSocialMediaReward,
  (socialMediaReward) => socialMediaReward
)

export const isDailyBonusAvailableWithConditionsSelector = createSelector(
  isDailyBonusAvailableSelector,
  dailyRewardModalLastTimeShownSelector,
  // isTenDaysSinceRegistrationSelector,
  // isTenDaysSinceLastDepositSelector,
  (bonuses, dateShowModal /* dateRegistration, firstDeposit */) => {
    const is24HoursPassedAfterRewordModalShown = hasPassedTime(dateShowModal, 86400000)

    return bonuses && is24HoursPassedAfterRewordModalShown /* && (dateRegistration || firstDeposit)  */
  }
)

export const isDailyBonusAvailableWithGCGeneratorConditionsSelector = createSelector(
  isDailyBonusAvailableSelector,
  getGcGeneratorEnabled,
  (bonuses, isGcGeneratorEnabled) => Boolean(bonuses && isGcGeneratorEnabled)
)

export const getDailyRewardVisibleSelector = createSelector(
  isLoggedInSelector,
  (_: ReduxState, isDailyBonusVisible) => isDailyBonusVisible,
  (isLoggedin, isDailyBonusVisible) => isLoggedin && isDailyBonusVisible && BRAND_MCLUCK
)

export const isDailyBonusAvailableWithCommonConditionsAndGCGeneratorSelector = createSelector(
  isDailyBonusAvailableWithConditionsSelector,
  isDailyBonusAvailableWithGCGeneratorConditionsSelector,
  (isDailyBonusAvailableWithConditions, isDailyBonusAvailableWithGCGeneratorConditions) =>
    (isDailyBonusAvailableWithConditions && !isDailyBonusAvailableWithGCGeneratorConditions) ||
    isDailyBonusAvailableWithGCGeneratorConditions
)

export const isIgnoreAlwaysOpenDailyBonusSelector = createSelector(
  isDailyBonusAvailableWithConditionsSelector,
  isDailyBonusAvailableWithGCGeneratorConditionsSelector,
  (isDailyBonusAvailableWithConditions, isDailyBonusAvailableWithGCGeneratorConditions) =>
    isDailyBonusAvailableWithConditions && !isDailyBonusAvailableWithGCGeneratorConditions
)

export const isSocialMediaRewardAvailableSelector = createSelector(isLoggedInSelector, (isLoggedIn) => {
  const rewardCode = getDataFromSessionStorage(REWARD_CODE)

  return Boolean(isLoggedIn && rewardCode)
})
