/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type AccountSessionRestriction = {
    type: AccountSessionRestriction.type;
    startAt: string;
    endAt: string;
    reason?: string;
};
export namespace AccountSessionRestriction {
    export enum type {
        COOL_OFF = 'COOL_OFF',
        SELF_EXCLUSION = 'SELF_EXCLUSION',
    }
}

