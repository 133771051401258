import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { FreeSpinsState } from 'store/types/FreeSpinsModule'
import type { FreeSpinType } from '@patrianna/shared-patrianna-types/store/FreeSpinModule'

const initialState: FreeSpinsState = {
  freeSpins: [],
  loading: false,
}

export const freeSpinsSlice = createSlice({
  name: 'freeSpins',
  initialState,
  reducers: {
    saveFreeSpins: (state, action: PayloadAction<{ freeSpins: FreeSpinType[] }>) => {
      state.freeSpins = action.payload.freeSpins
    },
    setFreeSpinsLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
  },
})

export type ActionsTypes = ReturnType<InferValueTypes<typeof freeSpinsSlice.actions>>
export const { actions } = freeSpinsSlice
