/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $InboxNotification = {
    properties: {
        id: {
            type: 'string',
        },
        token: {
            type: 'string',
        },
        title: {
            type: 'string',
        },
        message: {
            type: 'string',
            isRequired: true,
        },
        categoryCode: {
            type: 'string',
        },
        status: {
            type: 'Enum',
        },
        createdAt: {
            type: 'number',
            format: 'int64',
        },
    },
} as const;
