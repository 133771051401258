import classes from './RewardsButton.module.scss'
import Button from 'components/Button'
import { useAppSelector } from 'store/hooks'
import { getFreeSpinsLoadingSelector, getFreeSpinsSelector } from 'store/modules/freeSpins/selectors'
import MenuRewardsBadge from './components/MenuRewardsBadge'
import type { RewardsButtonProps } from './RewardsButton.types'
import { useTranslation } from 'app/context/translations'

export default function RewardsButton({ handleOpen }: RewardsButtonProps) {
  const t = useTranslation()
  const freeSpins = useAppSelector(getFreeSpinsSelector)
  const isFsLoading = useAppSelector(getFreeSpinsLoadingSelector)

  return (
    <Button variant='outlined' className={classes.button} onClick={handleOpen}>
      <div>
        <img width={24} height={24} src={`${process.env.IMG_ORIGIN}/menu/reward.svg`} alt='menu reward icon' />

        <span>{t('myRewards.title')}</span>
      </div>

      <div>
        <MenuRewardsBadge rewardsCount={freeSpins.length} loading={isFsLoading} />

        <img
          width={14}
          height={14}
          src={`${process.env.IMG_ORIGIN}/menu/arrow-right.svg`}
          alt='menu reward arrow right'
        />
      </div>
    </Button>
  )
}
