import { Builder } from '@builder.io/react'
import GamesByIdContainer from 'components/BuildersCustomComponents/GamesByIdContainer'
import GamesByTagContainer from 'components/BuildersCustomComponents/GamesByTagContainer'
import GamesRowByTagContainerWithScroll from 'components/BuildersCustomComponents/GamesRowByTagContainerWithScroll'
import PromotionTermsAccordion from 'components/BuildersCustomComponents/PromotionTermsAccordion'
import PromotionTitleWithBackButton from 'components/BuildersCustomComponents/PromotionTitleWithBackButton'
import ReferFriendLink from 'components/BuildersCustomComponents/ReferFriendLink'
import JackpotOptInButton from 'components/BuildersCustomComponents/JackpotOptInButton'
import SpecialOffersForBuilder from 'components/SpecialOffersForBuilder'
import OptimizedImage from '@patrianna/core-components/src/components/OptimizedImage'
import images from 'temp/images.json'
import { getImageSettingsByType } from 'utils/getImageSettingsByType'
import Jackpots from 'containers/Jackpots'

// register custom component to builder
export default function RegisteredBuilderCustomComponents() {
  Builder.registerComponent(GamesByTagContainer, {
    name: 'getGamesByTag',
    inputs: [{ name: 'tag', type: 'text' }],
    image: `${process.env.IMG_ORIGIN}/menu/rocket.svg`,
  })

  Builder.registerComponent(GamesRowByTagContainerWithScroll, {
    name: 'getGamesRowByTagWithScroll',
    inputs: [
      { name: 'title', type: 'text' },
      { name: 'tag', type: 'text' },
    ],
    image: `${process.env.IMG_ORIGIN}/menu/slots.svg`,
  })

  Builder.registerComponent(GamesByIdContainer, {
    name: 'getGamesById',
    inputs: [{ name: 'gameCodes', type: 'text' }],
    image: `${process.env.IMG_ORIGIN}/menu/slots.svg`,
  })

  Builder.registerComponent(SpecialOffersForBuilder, {
    name: 'SpecialOffers',
    inputs: [{ name: 'title', type: 'text' }],
    image: `${process.env.IMG_ORIGIN}/gold-coins-page/special-discount-cherry.svg`,
  })

  Builder.registerComponent(PromotionTitleWithBackButton, {
    name: 'TitleWithBackButton',
    inputs: [{ name: 'title', type: 'text' }],
    image: `${process.env.IMG_ORIGIN}/common/left.svg`,
  })

  Builder.registerComponent(PromotionTermsAccordion, {
    name: 'TermsAccordion',
    inputs: [
      { name: 'title', type: 'text' },
      { name: 'content', type: 'richText', defaultValue: '' },
    ],
    image: `${process.env.IMG_ORIGIN}/menu/chat.svg?v=1`,
  })

  Builder.registerComponent(ReferFriendLink, {
    name: 'ReferFriendLink',
  })

  Builder.registerComponent(JackpotOptInButton, {
    name: 'JackpotOptInButton',
    inputs: [{ name: 'content', type: 'richText', defaultValue: '' }],
  })
}

export function registerOptimizedImageComponent(imageType: string) {
  const options = {
    name: 'OptimizedImage',
    inputs: [
      { name: 'url', type: 'text' },
      { name: 'alt', type: 'text' },
    ],
  }
  const imageSettings = getImageSettingsByType(imageType, images)

  Builder.registerComponent((params: any) => <OptimizedImage {...params} imageSettings={imageSettings} />, options)
}

export function registerJackpotsComponent(hideFooter: boolean, smallImages: boolean) {
  Builder.registerComponent(() => <Jackpots hideFooter={hideFooter} smallImages={smallImages} className='noSpace' />, {
    name: 'Jackpots',
    image: `${process.env.IMG_ORIGIN}/menu/jackpot.svg`,
  })
}
