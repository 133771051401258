import RewardsDrawer from './components/RewardsDrawer/RewardDrawer'
import { useRewardsHandlers } from './hooks/useRewardsHandlers'
import RewardsButton from 'containers/Menu/MenuRewards/components/RewardsButton'

export default function MenuRewards() {
  const { open, handleOpen, handleClose } = useRewardsHandlers()

  return (
    <>
      <RewardsButton handleOpen={handleOpen} />

      <RewardsDrawer open={open} onClose={handleClose} />
    </>
  )
}
