import cx from 'classnames'

import classes from './LinearProgress.module.scss'
import type { LinearProgressProps } from './LinearProgress.types'

function LinearProgress({ className }: LinearProgressProps) {
  return (
    <div className={cx(classes.linearProgress, className)}>
      <span className={cx(classes.bar, classes.barOne)} />
      <span className={cx(classes.bar, classes.barTwo)} />
    </div>
  )
}

export default LinearProgress
