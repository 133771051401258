import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { ILevel, User } from '@patrianna/shared-patrianna-types/store/UserModule'
import type { PaymentMetaDataInfo } from '@patrianna-payments/shared-store/payments/types/PaymentMetaDataInfo'
import type { UserState } from 'store/types/UserModule'
import type { LiveChatSettings } from '@patrianna/shared-patrianna-types/store/UserModule'

const initialState: UserState = {
  info: null,
  location: null,
  xpLevel: null,
  firstOffer: null,
  vipLevel: null,
  userFlowIsRunning: false,
  isUpdateingLegals: false,
  isLoadingInfo: false,
  paymentMetaDataInfo: {
    firstDeposit: null,
    lastDeposit: null,
  },
  liveChatSettings: {
    showLiveChat: false,
    purchaseFlowEnabled: false,
    email: null,
    external_id: null,
  },
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<{ data: User }>) => {
      state.info = action.payload.data
    },

    setUserCountry: (state, action: PayloadAction<{ country: string }>) => {
      if (state.location) {
        state.location.country = action.payload.country
      } else {
        state.location = {
          country: action.payload.country,
        }
      }
    },

    setXPLevel: (state, action: PayloadAction<{ xpLevel: ILevel }>) => {
      state.xpLevel = action.payload.xpLevel
    },

    setIsEmailVerified: (state, action: PayloadAction<{ isVerified: boolean }>) => {
      if (state.info) {
        state.info.emailVerified = action.payload.isVerified
      } else {
        console.warn('setIsEmailVerified - no user data')
      }
    },

    deleteUser: () => initialState,

    setUserFlowIsRunning: (state, action: PayloadAction<{ userFlowIsRunning: boolean }>) => {
      state.userFlowIsRunning = action.payload.userFlowIsRunning
    },

    setIsLoadingInfo: (state, action: PayloadAction<{ loading: boolean }>) => {
      state.isLoadingInfo = action.payload.loading
    },

    setSentEmail: (state) => {
      if (state.info) {
        state.info.sentWelcomeEmail = true
      } else {
        console.warn('setSentEmail - no user data')
      }
    },

    setKycStatus: (state, action: PayloadAction<{ kycStatus: User['kycStatus'] }>) => {
      if (state.info) {
        state.info.kycStatus = action.payload.kycStatus
      } else {
        console.warn('setKycStatus - no user data')
      }
    },

    setIsUpdateLegals: (state, action: PayloadAction<{ isUpdateingLegals: boolean }>) => {
      state.isUpdateingLegals = action.payload.isUpdateingLegals
    },

    setFirstDepositDate: (state, action: PayloadAction<{ firstDepositDate: string }>) => {
      state.paymentMetaDataInfo.firstDeposit = action.payload.firstDepositDate
    },

    setPaymentMetaDataInfo: (state, action: PayloadAction<PaymentMetaDataInfo>) => {
      state.paymentMetaDataInfo = {
        firstDeposit: action.payload.firstDeposit || null,
        lastDeposit: action.payload.lastDeposit || null,
      }
    },

    setLiveChatSettings: (state, action: PayloadAction<{ liveChatSettings: LiveChatSettings }>) => {
      state.liveChatSettings = action.payload.liveChatSettings
    },
  },
})

export type ActionsTypes = ReturnType<InferValueTypes<typeof userSlice.actions>>
export const { actions } = userSlice
