/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $GetGiveawayResponse = {
    properties: {
        giveaway: {
            type: 'Giveaway',
        },
        influencer: {
            type: 'Influencer',
        },
        requestorInfo: {
            type: 'GiveawayRequestorInfo',
        },
    },
} as const;
