import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'src/store/hooks'
import { getJackpotTotals } from 'src/store/modules/jackpots/actions'
import { setDefaultActivatedGame } from 'src/store/modules/shop/actions'
import { userInitilization } from 'src/store/modules/userInitilization/actions'
import { isLoggedInSelector } from 'store/modules/user/selectors'
import getAccountSettingsRequest from 'src/services/gateway/requests/getAccountSettings'
import { usePathname, useSearchParams } from 'next/navigation'
import { useInitAnalytic } from '@patrianna-payments/analytics/main'
import { analyticConfig, defaultAnalyticOptions } from 'config/analytic'
import { useRouter } from 'app/context/navigation'
import { fetchCategoryGames } from 'store/modules/categoryGames'
import { initSocketListeners } from 'store/middlewares/initSocketListenersMiddleware'

export default function useAppInit() {
  const { push } = useRouter()
  const query = useSearchParams()
  const pathname = usePathname()
  const dispatch = useAppDispatch()
  const isLoggedIn = useSelector(isLoggedInSelector)

  useInitAnalytic(analyticConfig, defaultAnalyticOptions)

  useEffect(() => {
    dispatch(setDefaultActivatedGame())
    dispatch(getAccountSettingsRequest())
    dispatch(getJackpotTotals())
    dispatch(userInitilization(push, query, pathname))
    // eslint-disable-next-line react-hooks-static-deps/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(fetchCategoryGames())
      dispatch(initSocketListeners())
    }
  }, [isLoggedIn])
}
