/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type UpdateFraudInboxNotificationRequestBody = {
    /**
     * Token of notification returned in [Get inbox notifications](#operation/getInboxNotifications)
     */
    notificationToken?: string;
    /**
     * New status
     */
    status: UpdateFraudInboxNotificationRequestBody.status;
};
export namespace UpdateFraudInboxNotificationRequestBody {
    /**
     * New status
     */
    export enum status {
        READ = 'read',
        UNREAD = 'unread',
        REMOVED = 'removed',
    }
}

