import { getSeonSessionId } from '@patrianna-payments/analytics/seon'
import generateQueryField from '@patrianna-payments/analytics/utils/generateQueryField'
import type { FormikErrors } from 'formik'
import type { AuthMethod, LoginFormValues } from '@patrianna/shared-patrianna-types/store/AuthModule'
import type { AuthErrorHandleActionName, TypedThunkAuth } from '@patrianna/shared-store/auth'
import {
  authDetectExpiredSession,
  authLogin,
  authLogoutRequest,
  authRegisterViaEmail,
  authResendEmailRequest,
  authRunsCurrentAuthFlow,
  authSignInPermanent,
  authSocialSignInHandler,
  authSocialSignUpHandler,
  getShouldDelayLogoutSelector,
  resetPasswordAction,
  setAuthLoading,
  setShouldDelayLogout,
} from '@patrianna/shared-store/auth'
import { clearPaymentsState } from '@patrianna-payments/shared-store/payments/store/actions'
import { clearRedeemState } from '@patrianna-payments/shared-store/redeem'
import { createDateInFormat } from '@patrianna/shared-utils'
import { setCookie } from '@patrianna/shared-utils/cookie'
import { getFacebookUserData, initFacebookApp } from 'src/analyticActions/facebook/utils'
import type { TypedThunk } from 'src/store/types'
import type { AuthViaSocialAPI, RegisterViaEmail } from 'store/modules/auth/types'
import { clearCategoryGames } from 'store/modules/categoryGames'
import { clearCurrency } from 'store/modules/currencies/actions'
import { closeAllDialogs, closeLatestDialog, openDialog, removeDialogByName } from 'store/modules/dialog/actions'
import { clearPlayedGames } from 'store/modules/playedGames/actions'
import { clearVisitedPromotions } from 'store/modules/promotions'
import { closeSnackbar, openSnackbar } from 'store/modules/snackbar/actions'
import { deleteUser } from 'store/modules/user/actions'
import { getForcementModeSelector } from 'store/modules/appConfig/selectors'
import { isLoggedInSelector } from 'store/modules/user/selectors'
import {
  getItemFormLocalStorageSelector,
  IS_USER_REGISTERED,
  LS_AUTH_TYPE,
  removeDataFromLocalStorage,
  SAVED_UPCOMMING_GAMES,
  SESSION_ID,
  setDataToLocalStorage,
  IS_SIGN_UP_SESSION,
  USER_ID,
  PAYMENT_REDIRECT_OFFER_CODE,
} from 'utils/localStorage'
import { FACEBOOK_APP_ID } from 'src/config/apps'
import ROUTES from 'temp/routes.json'
import type { useSearchParams } from 'next/navigation'
import { trackEvent } from 'config/analytic'
import { resetAnalytic } from '@patrianna-payments/analytics/main/events'
import { LOGGED_IN } from '@patrianna/shared-utils/constants/cookies'
import { clearAuthCookies } from 'src/utils/clearAuthCookies'
import { getSnackbarVisibilitySelector } from 'store/modules/snackbar/selectors'
import { clearTargetBanners } from '@patrianna/shared-store/banners/store/actions'
import { removeDataFromSessionStorage, ZENDESK_JWT_TOKEN } from 'utils/sessionStorage'

export { resetPasswordAction, setAuthLoading }

export const authSuccessHandle =
  (options = { doNotCloseSnackbar: false }): TypedThunk =>
  (dispatch) => {
    dispatch(removeDialogByName({ modalName: 'AUTH_CONFIRM_DIALOG' }))
    dispatch(removeDialogByName({ modalName: 'CONNECT_ACCOUNT_DIALOG' }))
    dispatch(removeDialogByName({ modalName: 'PRELOADER_DIALOG' }))

    dispatch(setDataToLocalStorage(IS_USER_REGISTERED, true, false))

    dispatch(removeDataFromLocalStorage(SAVED_UPCOMMING_GAMES))
    dispatch(removeDataFromLocalStorage('REFERRER', false))
    dispatch(removeDataFromLocalStorage('QUERY', false))

    dispatch(setAuthLoading({ loading: false }))

    setCookie(LOGGED_IN, '1', { path: '/', expires: new Date(Date.now() + 31536000000) })
    setCookie('USER_REGISTERED', '1', { path: '/', expires: new Date(Date.now() + 31536000000) })

    if (!options?.doNotCloseSnackbar) {
      dispatch(closeSnackbar())
    }
  }

export const authErrorHandler =
  ({ err_code, oauth, options }: AuthErrorHandleActionName): TypedThunk =>
  (dispatch, getState) => {
    if ('isMobile' in options && !options.isMobile) {
      dispatch(closeLatestDialog())
    }

    dispatch(setAuthLoading({ loading: false }))

    switch (err_code) {
      case 'err_duplicate': {
        dispatch(closeAllDialogs())

        if (oauth) {
          dispatch(
            openDialog({
              modalName: 'USER_EXISTS_DIALOG',
              dialogProps: {
                provider: oauth,
              },
            })
          )
        }

        if (options?.errorText && !oauth) {
          dispatch(
            openSnackbar({
              message: options?.errorText,
            })
          )
        }
        break
      }
      case 'err_frozen': {
        dispatch(openDialog({ modalName: 'LOCK_USER_DIALOG', dialogProps: { singletone: true } }))
        break
      }
      default: {
        const isSnackbarAlreadyShown = getSnackbarVisibilitySelector(getState())
        if (!isSnackbarAlreadyShown) {
          dispatch(
            openSnackbar({
              message: 'Please, try again later',
            })
          )
        }
        break
      }
    }
  }

export const login =
  (
    form: LoginFormValues,
    routerPush: (path: string, callback?: () => void) => void,
    routerQuery: ReturnType<typeof useSearchParams>,
    routerPath: string,
    setSubmitting?: (arg: boolean) => void,
    setErrors?: (errors: FormikErrors<LoginFormValues>) => void,
    isMobile?: boolean,
    session?: string,
    captchaToken?: string,
    setLoginPassed?: (arg: boolean) => void,
    onError?: () => void
  ): TypedThunk =>
  async (dispatch, getState) => {
    const query = getItemFormLocalStorageSelector(getState(), 'QUERY', false)

    dispatch(
      authLogin({
        form,
        query,
        brandName: process.env.BRAND_NAME,
        session,
        setSubmitting,
        setErrors,
        setLoginPassed,
        isMobile,
        routerPush,
        routerQuery,
        routerPath,
        captchaToken,
        onError,
      })
    )
  }

export const socialSignInHandler =
  (
    form: AuthViaSocialAPI,
    method: AuthMethod,
    isMobile: boolean,
    routerPush: (path: string, callback?: () => void) => void,
    routerQuery: ReturnType<typeof useSearchParams>,
    routerPath: string,
    email?: string
  ): TypedThunk =>
  async (dispatch, getState) => {
    const forcement = getForcementModeSelector(getState())
    const query = getItemFormLocalStorageSelector(getState(), 'QUERY', false)
    const session = await getSeonSessionId()
    const authType = getItemFormLocalStorageSelector(getState(), LS_AUTH_TYPE, false)
    const paymentRedirectOfferCode = getItemFormLocalStorageSelector(getState(), PAYMENT_REDIRECT_OFFER_CODE, false)

    dispatch(
      authSocialSignInHandler({
        form,
        method,
        query,
        email,
        forcement,
        session,
        brandName: process.env.BRAND_NAME,
        isMobile,
        showSuccessDialog: () => {
          if (authType === 'REGISTRATION') {
            dispatch(openDialog({ modalName: 'USER_BEEN_LOGGED_IN_DIALOG' }))
          }
        },
        routerPush,
        routerQuery,
        routerPath,
        reloadPage: () => {
          // skip reload when payment modal should be visible after login
          if (paymentRedirectOfferCode) {
            return null
          }

          if (method === 'Facebook' || method === 'Google') {
            console.log('RRRRRR')
            window.location.reload()
          }
        },
      })
    )
  }

export const signInPermanent =
  (
    token: string,
    launchGameParams: { gameDialogName: string; gameCode: string },
    routerPush: (path: string, callback?: () => void) => void,
    routerQuery: ReturnType<typeof useSearchParams>,
    routerPath: string
  ): TypedThunkAuth =>
  async (dispatch) => {
    const session = await getSeonSessionId()

    dispatch(
      authSignInPermanent({
        token,
        launchGameParams,
        session,
        routerPush,
        routerQuery,
        routerPath,
        route: ROUTES.HOME,
      })
    )
  }

export const registerViaEmail =
  (
    form: RegisterViaEmail,
    setSubmitting: (arg: boolean) => void,
    isMobile: boolean,
    routerPush: (path: string, callback?: () => void) => void,
    routerQuery: ReturnType<typeof useSearchParams>,
    routerPath: string,
    session: string
  ): TypedThunk =>
  async (dispatch, getState) => {
    const forcement = getForcementModeSelector(getState())
    const referrer = getItemFormLocalStorageSelector(getState(), 'REFERRER', false)
    const query = getItemFormLocalStorageSelector(getState(), 'QUERY', false)
    const first_utm = getItemFormLocalStorageSelector(getState(), 'FIRST_UTM', false)
    const { day, month, year, ...restFields } = form
    const birthDate = createDateInFormat(year, month, day)

    dispatch(
      authRegisterViaEmail({
        form: restFields,
        setSubmitting,
        isMobile,
        forcement,
        referrer,
        query: generateQueryField(first_utm, query),
        session,
        first_utm,
        brandName: process.env.BRAND_NAME,
        birthDate,
        routerPush,
        routerQuery,
        routerPath,
        route: ROUTES.CONFIRM_EMAIL,
        doNotSendSMS: true,
      })
    )
  }

export const socialSignUpHandler =
  (
    form: AuthViaSocialAPI,
    method: AuthMethod,
    routerPush: (path: string, callback?: () => void) => void,
    routerQuery: ReturnType<typeof useSearchParams>,
    routerPath: string,
    setSubmitting?: (arg: boolean) => void,
    isMobile?: boolean
  ): TypedThunk =>
  async (dispatch, getState) => {
    const forcement = getForcementModeSelector(getState())
    const referrer = getItemFormLocalStorageSelector(getState(), 'REFERRER', false)
    const query = getItemFormLocalStorageSelector(getState(), 'QUERY', false)
    const first_utm = getItemFormLocalStorageSelector(getState(), 'FIRST_UTM', false)
    const session = await getSeonSessionId()
    const paymentRedirectOfferCode = getItemFormLocalStorageSelector(getState(), PAYMENT_REDIRECT_OFFER_CODE, false)

    dispatch(
      authSocialSignUpHandler({
        form,
        method,
        setSubmitting,
        isMobile,
        forcement,
        referrer,
        query: generateQueryField(first_utm, query),
        session,
        first_utm,
        brandName: process.env.BRAND_NAME,
        routerPush,
        routerQuery,
        routerPath,
        route: ROUTES.HOME,
        doNotSendSMS: true,
        reloadPage: () => {
          // skip reload when payment modal should be visible after login
          if (paymentRedirectOfferCode) {
            return null
          }

          if (method === 'Facebook' || method === 'Google') {
            console.log('RRRRRR')
            window.location.reload()
          }
        },
      })
    )
  }

export const logOutSuccess =
  ({ cbUrl }: { cbUrl: string }): TypedThunk =>
  (dispatch) => {
    trackEvent('set_user_id', {}, { all: false, gtm: true })
    dispatch(removeDataFromLocalStorage(SAVED_UPCOMMING_GAMES))
    dispatch(removeDataFromLocalStorage(USER_ID, false))
    dispatch(removeDataFromLocalStorage(SESSION_ID, false))
    dispatch(removeDataFromLocalStorage(IS_SIGN_UP_SESSION, false))

    dispatch(deleteUser())
    dispatch(clearCurrency())
    dispatch(clearPlayedGames())
    dispatch(clearCategoryGames())
    dispatch(clearRedeemState())
    dispatch(clearPaymentsState())
    dispatch(clearVisitedPromotions())
    dispatch(clearTargetBanners())

    clearAuthCookies()

    window.location.href = cbUrl
  }

export const logoutRequest =
  (cbUrl: string = ROUTES.HOME): TypedThunk =>
  (dispatch) => {
    dispatch(authLogoutRequest({ cbUrl, brandName: process.env.BRAND_NAME }))
    removeDataFromSessionStorage(ZENDESK_JWT_TOKEN)
  }
export const logOut = (): TypedThunk => (dispatch, getState) => {
  const loggedIn = isLoggedInSelector(getState())
  const shouldDelayLogout = getShouldDelayLogoutSelector(getState())

  if (loggedIn) {
    if (shouldDelayLogout) {
      setTimeout(() => {
        dispatch(logoutRequest())
        dispatch(setShouldDelayLogout({ delayed: false }))
      }, 3000)
    } else {
      dispatch(logoutRequest())
    }
  } else {
    //eslint-disable-next-line
    console.log("you've already logged out")
  }
  resetAnalytic()
}

const socialAuthRedirectHanlder =
  (
    token: string,
    email: string,
    socialProvider: 'Facebook' | 'Google',
    routerPush: (path: string, callback?: () => void) => void,
    routerQuery: ReturnType<typeof useSearchParams>,
    routerPath: string
  ): TypedThunkAuth =>
  async (dispatch) => {
    // Renew AuthLoading state as we are losing it after redirect from Facebook/Google
    dispatch(setAuthLoading({ loading: true }))

    dispatch(
      openDialog({ modalName: 'PRELOADER_DIALOG', dialogProps: { title: `${socialProvider} auth in progress` } })
    )

    dispatch(
      socialSignInHandler({ accessToken: token }, socialProvider, false, routerPush, routerQuery, routerPath, email)
    )
  }

export const googleOneTapAuthHandler =
  (
    token: string,
    email: string,
    routerPush: (path: string, callback?: () => void) => void,
    routerQuery: ReturnType<typeof useSearchParams>,
    routerPath: string
  ): TypedThunkAuth =>
  async (dispatch) => {
    dispatch(openDialog({ modalName: 'PRELOADER_DIALOG', dialogProps: { title: 'Google auth in progress' } }))
    dispatch(socialSignInHandler({ accessToken: token }, 'Google', false, routerPush, routerQuery, routerPath, email))
  }

export const runsCurrentAuthFlow =
  (
    isLoggedIn: boolean,
    routerQuery: ReturnType<typeof useSearchParams>,
    routerPush: (path: string, callback?: () => void) => void,
    routerPath: string
  ): TypedThunk =>
  async (dispatch) => {
    await initFacebookApp(FACEBOOK_APP_ID).catch((err) => {
      // eslint-disable-next-line no-console
      console.info('Facebook SDK loadScript error', err)
    })

    dispatch(
      authRunsCurrentAuthFlow({
        isLoggedIn,
        query: {
          a: routerQuery.get('a'),
          gameDialogName: routerQuery.get('gameDialogName'),
          gameCode: routerQuery.get('gameCode'),
          invited_by: routerQuery.get('invited_by'),
        },
        signInPermanent,
        socialAuthRedirectHanlder,
        getFacebookUserData,
        routerPush,
        routerQuery,
        routerPath,
        route: ROUTES.REGISTER,
      })
    )
  }

export const resendEmailRequest =
  (): TypedThunk =>
  (dispatch, getState, { gateway, errorHandler }) => {
    // @ts-ignore
    dispatch(authResendEmailRequest({ gatewayInstance: gateway, gatewayErrorInstance: { errorHandler } }))
  }

export const detectExpiredSession =
  (isServerLoggedIn: boolean): TypedThunk =>
  (dispatch, getState) => {
    const isClientLoggedIn = isLoggedInSelector(getState())

    dispatch(authDetectExpiredSession({ isServerLoggedIn, isClientLoggedIn, cbUrl: ROUTES.HOME }))
  }
