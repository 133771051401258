/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $GameType = {
    properties: {
        code: {
            type: 'string',
        },
        featureKey: {
            type: 'string',
        },
    },
} as const;
