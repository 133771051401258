export function splitImageUrl(url?: string) {
  if (!url) {
    return { baseURL: '', name: '', ext: '' }
  }

  const lastSlashIndex = url.lastIndexOf('/')
  const baseURL = lastSlashIndex !== -1 ? url.substring(0, lastSlashIndex) : ''
  const name = url.replace('/source.', '.').split('/').pop()?.split('.')[0] || ''
  const ext = url.split('.').pop() || ''

  return { baseURL, name, ext }
}
