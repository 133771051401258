/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ErrorResponse } from '../models/ErrorResponse';
import type { InitialMissionAssignmentRequest } from '../models/InitialMissionAssignmentRequest';
import type { InitialMissionAssignmentResponse } from '../models/InitialMissionAssignmentResponse';
import type { MissionStepUpdateRequest } from '../models/MissionStepUpdateRequest';
import type { MissionStepUpdateResponse } from '../models/MissionStepUpdateResponse';
import type { qBrandName } from '../models/qBrandName';
import type { qPlatform } from '../models/qPlatform';
import type { xMessageId } from '../models/xMessageId';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class MissionsApiService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns InitialMissionAssignmentResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public assignInitialMission(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: InitialMissionAssignmentRequest,
        xChk?: string,
    ): CancelablePromise<InitialMissionAssignmentResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/missions/initial-assign',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns MissionStepUpdateResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public missionStepUpdateRequest1(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: MissionStepUpdateRequest,
        xChk?: string,
    ): CancelablePromise<MissionStepUpdateResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/missions/steps',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
