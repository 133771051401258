/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $GetPurchaseLimitsResponseBody = {
    properties: {
        internalLimits: {
            type: 'array',
            contains: {
                type: 'LimitInfo',
            },
        },
        memberRequestLimits: {
            type: 'array',
            contains: {
                type: 'LimitInfo',
            },
        },
        consumedLimits: {
            type: 'array',
            contains: {
                type: 'ConsumedLimitInfo',
            },
        },
        deferredLimits: {
            type: 'array',
            contains: {
                type: 'DeferredLimitInfo',
            },
        },
    },
} as const;
