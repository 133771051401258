import type { ReactElement, ReactNode } from 'react'

type TShow = {
  when: boolean
  fallback?: ReactElement
  children: ReactNode
}

/**
 * @deprecated
 * Use one from `@patrianna/core-components` instead
 * */
export const Show = ({ when, fallback, children }: TShow) => {
  if (when) return children
  if (fallback) return fallback

  return false
}
