import { useRef } from 'react'
import cx from 'classnames'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import classes from './ReferAFriendShopTile.module.scss'
import { useTranslation } from 'context/translationContext'
import { Information } from 'mdi-material-ui'
import CopyToClipboardInput from 'components/CopyToClipboardInput'
import { generateShareUrlWithUserId } from 'utils/url'
import { getUserAccountIdSelector } from 'store/modules/user/selectors'
import { Link } from 'components/Link'
import ROUTES from 'temp/routes.json'
import RichText from 'components/RichText'
import { SharingNativeMobile } from 'components/SharingNativeMobile'
import { closeAllDialogs } from 'store/modules/dialog/actions'
import { useEffect, useState } from 'react'
import { useIsMobile } from '@patrianna/core-components/src/context/mobileDetectionContext'
import SpecialShopTileLabel from 'components/ShopTile/components/SpecialShopTileLabel'

export default function ReferAFriendShopTile() {
  const t = useTranslation()
  const accountId = useAppSelector(getUserAccountIdSelector)
  const shareUrl = generateShareUrlWithUserId(accountId)
  const dispatch = useAppDispatch()
  const isMobile = useIsMobile()
  const benefitsRef = useRef(null)
  const featuresRef = useRef(null)

  const [isShareAvailable, setIsShareAvailable] = useState(false)

  useEffect(() => {
    if (window.navigator?.canShare && isMobile) {
      setIsShareAvailable(true)
    }
  }, [isMobile])

  useEffect(() => {
    if (featuresRef.current?.offsetWidth > benefitsRef.current?.offsetWidth) {
      featuresRef.current?.classList.add(classes.animated)
    }
  }, [])

  const closeShopModal = () => {
    if (isMobile) {
      dispatch(closeAllDialogs())
    }
  }

  return (
    <div className={classes.root}>
      <SpecialShopTileLabel>
        <span>{t('pageReferFriend.shop_tile_corner_badge')}</span>
      </SpecialShopTileLabel>
      <img
        src={`${process.env.IMG_ORIGIN}/dialogs/refer-a-friend.png?v2`}
        className={cx(classes.specialIcon, classes.guy)}
        height='auto'
        alt='reward'
      />

      <h5 className={classes.title}>{t('pageReferFriend.shop_tile_title')}</h5>

      {isShareAvailable ? (
        <SharingNativeMobile className={classes.shareButton} url={shareUrl} />
      ) : (
        <CopyToClipboardInput className={classes.button} hideInputText inputText={shareUrl} />
      )}

      <div className={classes.benefits} ref={benefitsRef}>
        <Link className={classes.linkInfo} href={ROUTES.REFER_FRIEND} onClick={closeShopModal} rel='noreferrer'>
          <div className={classes.features} ref={featuresRef}>
            <RichText
              field={t('pageReferFriend.shop_tile_benefits')}
              components={{
                paragraph: ({ children }) => <span className={classes.featureText}>{children}</span>,
                em: ({ children }) => (
                  <>
                    <div className={classes.currencyIcon}>
                      <img
                        src={`${process.env.IMG_ORIGIN}/menu/money22.svg?v=3`}
                        alt='Currency icon'
                        width={20}
                        height={20}
                        className={classes.shopTileImage}
                      />
                    </div>
                    {children}
                  </>
                ),
              }}
            />
            <Information />
          </div>
        </Link>
      </div>
    </div>
  )
}
