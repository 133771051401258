import type { RTTextNode } from '@prismicio/types'
import { replacePrismicDynamicValues } from '@patrianna/shared-utils/prismic'

export function getTranslationText(
  key: string,
  translations: any,
  isDebugMode: boolean,
  dynamicValues?: Record<string, string | number>
): string | RTTextNode[] {
  const [type, field] = key.split('.')
  const translation: RTTextNode = translations?.[type]?.[field]
  const isValidKey = type && field

  // If the key is not valid, return an empty string or the key itself if debug mode is enabled
  if (!isValidKey) {
    return isDebugMode ? `{${key} is not valid}` : ''
  }

  // If the translation is empty, return an empty string or the key itself if debug mode is enabled
  if (!translation) {
    return isDebugMode ? `{${key} is not translated}` : ''
  }

  // If the translation is a string, return the string or the string with the type if debug mode is enabled
  if (typeof translation === 'string') {
    if (dynamicValues && !isDebugMode) {
      return replacePrismicDynamicValues(translation, dynamicValues)
    }

    return isDebugMode ? `${translation} - {${type}}` : translation
  }

  // If the translation is an array, return the array or the array with the type if debug mode is enabled
  if (Array.isArray(translation)) {
    const modifiedFields = translation.map((item) => {
      return { ...item, text: isDebugMode ? `${item.text} - {${type}}` : item.text }
    })

    return modifiedFields
  }

  return key
}
