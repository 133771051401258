/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $UpdateFraudInboxNotificationResponseBody = {
    description: `Returns updated notification`,
    properties: {
        notification: {
            type: 'InboxNotification',
            isRequired: true,
        },
    },
} as const;
