/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ListMissionsResponse = {
    properties: {
        missions: {
            type: 'array',
            contains: {
                type: 'MissionInfo',
            },
        },
        error: {
            type: 'string',
        },
    },
} as const;
