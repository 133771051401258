/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $SetPurchaseLimitResponseBody = {
    properties: {
        limits: {
            type: 'array',
            contains: {
                type: 'SetLimitResultInfo',
            },
        },
    },
} as const;
