import type { FreeSpinType } from '@patrianna/shared-patrianna-types/store/FreeSpinModule'
import { arrayFilterNullable, getOffsetDate } from '@patrianna/shared-utils'
import { notCountingSuppliers } from 'src/constants/freeSpins'
import type { GameProductModel } from '../../RewardsList.types'

const hideRounds = (freeSpin: FreeSpinType) =>
  freeSpin.products.some((p) => notCountingSuppliers.includes(p.supplier)) && freeSpin.status === 'active'

const getRoundsRemain = (freeSpin: FreeSpinType) => {
  const shouldHideRounds = hideRounds(freeSpin)

  return shouldHideRounds ? null : freeSpin.rounds - freeSpin.roundsPlayed
}

const mapFreeSpinToGameProduct = (freeSpin: FreeSpinType) => {
  const expiredAt = getOffsetDate(freeSpin.expireAt)
  const createdAt = getOffsetDate(freeSpin.creditedAt)
  const product = freeSpin.products[0]

  if (!product) return null

  const roundsRemain = getRoundsRemain(freeSpin)

  return {
    ...product,
    currency: freeSpin.currency,
    rounds: freeSpin.rounds,
    roundsRemain,
    freeSpinId: freeSpin.freeSpinId,
    expiredAt,
    createdAt,
  }
}

const sortFreeSpins = <T extends GameProductModel>(a: T, b: T): number => {
  if (!a.expiredAt && !b.expiredAt) return 0
  if (!a.expiredAt) return 1
  if (!b.expiredAt) return -1

  return a.expiredAt.getTime() - b.expiredAt.getTime()
}

export const getFreeSpinsGames = (freeSpins: FreeSpinType[]) => {
  const mappedFreeSpins = freeSpins.flatMap(mapFreeSpinToGameProduct)
  const filteredFreeSpins = arrayFilterNullable(mappedFreeSpins)

  return filteredFreeSpins.sort(sortFreeSpins)
}
