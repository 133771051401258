import { useState } from 'react'
import { nextTick } from '@patrianna/shared-utils'

export const useRewardsHandlers = () => {
  const [open, setOpen] = useState(false)

  const handleOpen = () => nextTick(() => setOpen(true))

  const handleClose = () => nextTick(() => setOpen(false))

  return {
    open,
    handleOpen,
    handleClose,
  }
}
