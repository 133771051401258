/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $SetAccountInfoResponse = {
    properties: {
        firstName: {
            type: 'string',
            isRequired: true,
        },
        lastName: {
            type: 'string',
            isRequired: true,
        },
        name: {
            type: 'string',
        },
        username: {
            type: 'string',
        },
        gender: {
            type: 'string',
        },
        birthDate: {
            type: 'string',
            format: 'date',
        },
        lang: {
            type: 'string',
        },
        email: {
            type: 'string',
            isRequired: true,
        },
        realEmail: {
            type: 'string',
        },
        profilePicture: {
            type: 'string',
            isRequired: true,
        },
        bot: {
            type: 'boolean',
            isRequired: true,
        },
        mode: {
            type: 'AccountsMode',
            isRequired: true,
        },
        id: {
            type: 'string',
            isRequired: true,
        },
        customerId: {
            type: 'string',
            isRequired: true,
        },
        phoneNumber: {
            type: 'string',
            isRequired: true,
        },
        guest: {
            type: 'boolean',
            isRequired: true,
        },
        admin: {
            type: 'boolean',
            isRequired: true,
        },
        test: {
            type: 'boolean',
            isRequired: true,
        },
        sweepstakeIntroAccepted: {
            type: 'boolean',
            isRequired: true,
        },
        onboardingPassed: {
            type: 'boolean',
            isRequired: true,
        },
        kyc: {
            type: 'boolean',
            isRequired: true,
        },
        kycStatus: {
            type: 'KYCStatus',
            isRequired: true,
        },
        restrictions: {
            type: 'AccountRestrictions',
            isRequired: true,
        },
        sentWelcomeEmail: {
            type: 'boolean',
            isRequired: true,
        },
        locked: {
            type: 'boolean',
            isRequired: true,
        },
        lockReason: {
            type: 'string',
        },
        invitation: {
            type: 'InvitationInfo',
            isRequired: true,
        },
        xpLevel: {
            type: 'LevelInfo',
            isRequired: true,
        },
        vipLevel: {
            type: 'LevelInfo',
            isRequired: true,
        },
        createdAt: {
            type: 'string',
            format: 'date-time',
        },
        sweepstakeIntroAcceptedAt: {
            type: 'string',
            format: 'date-time',
        },
        softKycInfo: {
            type: 'KYCInfo',
        },
        kycInfo: {
            type: 'KYCInfo',
        },
        emailVerified: {
            type: 'boolean',
            isRequired: true,
        },
        oauth: {
            type: 'array',
            contains: {
                type: 'string',
            },
            isRequired: true,
        },
        balances: {
            type: 'array',
            contains: {
                type: 'AccountBalanceInfo',
            },
        },
        tcVersion: {
            type: 'string',
            isRequired: true,
        },
        acceptedTcVersion: {
            type: 'string',
            isRequired: true,
        },
        purchaseVipLevel: {
            type: 'string',
        },
        srVersion: {
            type: 'string',
            isRequired: true,
        },
        acceptedSrVersion: {
            type: 'string',
            isRequired: true,
        },
        ptVersion: {
            type: 'string',
            isRequired: true,
        },
        acceptedPtVersion: {
            type: 'string',
            isRequired: true,
        },
        ppVersion: {
            type: 'string',
            isRequired: true,
        },
        acceptedPpVersion: {
            type: 'string',
            isRequired: true,
        },
        tcVersionGC: {
            type: 'string',
            isRequired: true,
        },
        acceptedTcVersionGC: {
            type: 'string',
            isRequired: true,
        },
        ppVersionGC: {
            type: 'string',
            isRequired: true,
        },
        acceptedPpVersionGC: {
            type: 'string',
            isRequired: true,
        },
        ptVersionGC: {
            type: 'string',
            isRequired: true,
        },
        acceptedPtVersionGC: {
            type: 'string',
            isRequired: true,
        },
        tcVersionNonUSGC: {
            type: 'string',
            isRequired: true,
        },
        acceptedTcVersionNonUSGC: {
            type: 'string',
            isRequired: true,
        },
        ppVersionNonUSGC: {
            type: 'string',
            isRequired: true,
        },
        acceptedPpVersionNonUSGC: {
            type: 'string',
            isRequired: true,
        },
        autoLockWithdraw: {
            type: 'boolean',
            isRequired: true,
        },
        doNotSendEmails: {
            type: 'boolean',
            isRequired: true,
        },
        doNotSendPushes: {
            type: 'boolean',
            isRequired: true,
        },
        doNotSendSms: {
            type: 'boolean',
            isRequired: true,
        },
        legalRules: {
            type: 'array',
            contains: {
                type: 'LegalRule',
            },
        },
        legalRuleDate: {
            type: 'string',
            format: 'date',
        },
        sweepstake: {
            type: 'boolean',
            isRequired: true,
        },
        status: {
            type: 'string',
        },
        location: {
            type: 'LocationInfo',
            isRequired: true,
        },
        personalInfo: {
            type: 'PersonalInfo',
            isRequired: true,
        },
        country: {
            type: 'string',
        },
        freeGoldCoins: {
            type: 'boolean',
            isRequired: true,
        },
        locale: {
            type: 'string',
        },
        isInfluencer: {
            type: 'boolean',
        },
        currencyMode: {
            type: 'dictionary',
            contains: {
                type: 'CurrencyPolicies',
            },
        },
        bucket: {
            type: 'string',
            description: `Stored customer unspecified information, external id, picture id, etc. Garbage in, garbage out.`,
        },
        locationStatus: {
            type: 'Enum',
            isRequired: true,
        },
        activeCoolOffRestriction: {
            type: 'AccountSessionRestriction',
        },
        accountSessionLimits: {
            type: 'AccountSessionLimitsInfo',
        },
    },
} as const;
