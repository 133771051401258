/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GameTypes } from './GameTypes';
import type { ProductInfoTags } from './ProductInfoTags';
import type { ProductModes } from './ProductModes';
export type ProductInfo = {
    code: string;
    title: string;
    route: string;
    provider: ProductInfo.provider;
    supplier: string;
    supplierSearchCode: string;
    type: GameTypes;
    mode: ProductModes;
    unlocked: boolean;
    hidden: boolean;
    fromLevel: number;
    maxLines?: number;
    rank: number;
    volatility: number;
    orientation: ProductInfo.orientation;
    moreInfo: boolean;
    miniMode?: boolean;
    launchDate?: string;
    tags: ProductInfoTags;
    themes?: Array<string>;
    /**
     * @deprecated
     */
    sweepstakeMinBet: number;
    /**
     * @deprecated
     */
    sweepstakeMaxWinMultiplier: number;
    /**
     * @deprecated
     */
    sweepstakeMaxBetMultiplier: number;
    scMinBet: number;
    scMaxWinMultiplier: number;
    scMaxBetMultiplier: number;
    goldMinBet: number;
    goldMaxWinMultiplier: number;
    goldMaxBetMultiplier: number;
    active: boolean;
    animatedTile: boolean;
    supportFreeSpins?: boolean;
};
export namespace ProductInfo {
    export enum provider {
        INTERNAL = 'internal',
        INTERNAL_LOTTO = 'internal_lotto',
        INTERNAL_JACKPOT = 'internal_jackpot',
        LOTTOLAND = 'lottoland',
        PRAGMATIC = 'pragmatic',
        PRAGMATIC_BINGO = 'pragmatic_bingo',
        NETENT = 'netent',
        GAMEVY = 'gamevy',
        MARKORTECH = 'markortech',
        PATRIANNA = 'patrianna',
        PRIZEOUT = 'prizeout',
        RELAX = 'relax',
        INFINGAME = 'infingame',
        EVOPLAY = 'evoplay',
    }
    export enum orientation {
        BOTH = 'both',
        PORTRAIT = 'portrait',
        LANDSCAPE = 'landscape',
    }
}

