/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ConsumedLimitInfo = {
    type: 'all-of',
    contains: [{
        type: 'LimitInfo',
    }, {
        properties: {
            limitConsumed: {
                type: 'string',
            },
        },
    }],
} as const;
