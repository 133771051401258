import type { RewardsDrawerProps } from '../RewardsDrawer.types'
import { useState } from 'react'

export const useDrawerAnimation = ({ onClose }: Pick<RewardsDrawerProps, 'onClose'>) => {
  const [animate, setAnimate] = useState(true)

  const handleClose = () => {
    setAnimate(false)

    setTimeout(() => {
      setAnimate(true)
      onClose()
    }, 200)
  }

  return {
    animate,
    handleClose,
  }
}
