/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $MissionStepInfo = {
    properties: {
        code: {
            type: 'string',
            isRequired: true,
        },
        achieved: {
            type: 'boolean',
            isRequired: true,
        },
        action: {
            type: 'string',
        },
        url: {
            type: 'string',
        },
        expires: {
            type: 'string',
            format: 'date-time',
        },
    },
} as const;
