/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BeginPaymentMethodVerificationRequestBody } from '../models/BeginPaymentMethodVerificationRequestBody';
import type { BeginPaymentMethodVerificationResponseBody } from '../models/BeginPaymentMethodVerificationResponseBody';
import type { ConfirmPhoneNumberVerificationRequestBody } from '../models/ConfirmPhoneNumberVerificationRequestBody';
import type { ConfirmPhoneNumberVerificationResponseBody } from '../models/ConfirmPhoneNumberVerificationResponseBody';
import type { CreatePhoneNumberVerificationRequestBody } from '../models/CreatePhoneNumberVerificationRequestBody';
import type { CreatePhoneNumberVerificationResponseBody } from '../models/CreatePhoneNumberVerificationResponseBody';
import type { GetAccountPhoneNumberDetailsRequestBody } from '../models/GetAccountPhoneNumberDetailsRequestBody';
import type { GetAccountPhoneNumberDetailsResponseBody } from '../models/GetAccountPhoneNumberDetailsResponseBody';
import type { GetCardVerificationsRequestBody } from '../models/GetCardVerificationsRequestBody';
import type { GetCardVerificationsResponseBody } from '../models/GetCardVerificationsResponseBody';
import type { GetFraudInboxNotificationsRequestBody } from '../models/GetFraudInboxNotificationsRequestBody';
import type { GetFraudInboxNotificationsResponseBody } from '../models/GetFraudInboxNotificationsResponseBody';
import type { GetLiveChatSettingsRequestBody } from '../models/GetLiveChatSettingsRequestBody';
import type { GetLiveChatSettingsResponseBody } from '../models/GetLiveChatSettingsResponseBody';
import type { GetSkipOtpCheckRequestBody } from '../models/GetSkipOtpCheckRequestBody';
import type { GetSkipOtpCheckResponseBody } from '../models/GetSkipOtpCheckResponseBody';
import type { OtpModalTriggeredRequestBody } from '../models/OtpModalTriggeredRequestBody';
import type { OtpModalTriggeredResponseBody } from '../models/OtpModalTriggeredResponseBody';
import type { qPlatform } from '../models/qPlatform';
import type { SaveChatEventRequestBody } from '../models/SaveChatEventRequestBody';
import type { SaveChatEventResponseBody } from '../models/SaveChatEventResponseBody';
import type { SetAccountFraudInfoRequestBody } from '../models/SetAccountFraudInfoRequestBody';
import type { SetAccountFraudInfoResponseBody } from '../models/SetAccountFraudInfoResponseBody';
import type { UpdateFraudInboxNotificationRequestBody } from '../models/UpdateFraudInboxNotificationRequestBody';
import type { UpdateFraudInboxNotificationResponseBody } from '../models/UpdateFraudInboxNotificationResponseBody';
import type { xMessageId } from '../models/xMessageId';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class FraudService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * SetAccountFraudInfoRequest
     * Update seon fingerprint for a given accountId. This endpoint allows for the updating of fraud information associated with an account. Now used because this separate endpoint because we can't pass seon fingerprint via Prizeout callbacks.
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @returns SetAccountFraudInfoResponseBody Account fraud info updated successfully
     * @throws ApiError
     */
    public setAccountFraudInfo(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: string,
        platform: qPlatform,
        requestBody: SetAccountFraudInfoRequestBody,
    ): CancelablePromise<SetAccountFraudInfoResponseBody> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/dispatch/SetAccountFraudInfoRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * CreatePhoneNumberVerificationRequest
     * Create phone verification of given phone number for user
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @returns CreatePhoneNumberVerificationResponseBody Phone number verification created and started successfully
     * @throws ApiError
     */
    public createPhoneNumberVerificationRequest(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: string,
        platform: qPlatform,
        requestBody: CreatePhoneNumberVerificationRequestBody,
    ): CancelablePromise<CreatePhoneNumberVerificationResponseBody> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/dispatch/CreatePhoneNumberVerificationRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * CreatePhoneNumberVerificationRequest
     * Confirm phone verification of given phone number for user
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @returns ConfirmPhoneNumberVerificationResponseBody Phone number verification created and started successfully
     * @throws ApiError
     */
    public confirmPhoneNumberVerificationRequest(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: string,
        platform: qPlatform,
        requestBody: ConfirmPhoneNumberVerificationRequestBody,
    ): CancelablePromise<ConfirmPhoneNumberVerificationResponseBody> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/dispatch/ConfirmPhoneNumberVerificationRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * GetAccountPhoneNumberDetailsRequest
     * Get account phone number verification details, such as country code
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @returns GetAccountPhoneNumberDetailsResponseBody Account phone number details
     * @throws ApiError
     */
    public getAccountPhoneNumberDetailsRequest(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: string,
        platform: qPlatform,
        requestBody: GetAccountPhoneNumberDetailsRequestBody,
    ): CancelablePromise<GetAccountPhoneNumberDetailsResponseBody> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/dispatch/GetAccountPhoneNumberDetailsRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * OtpModalTriggeredRequest
     * Create otp trigger rule
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @returns OtpModalTriggeredResponseBody Otp trigger rule
     * @throws ApiError
     */
    public createOtpTriggerRuleRequest(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: string,
        platform: qPlatform,
        requestBody: OtpModalTriggeredRequestBody,
    ): CancelablePromise<OtpModalTriggeredResponseBody> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/dispatch/OtpModalTriggeredRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * GetCardVerificationsRequest
     * Get all user card verifications
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @returns GetCardVerificationsResponseBody GetCardVerificationsResponse
     * @throws ApiError
     */
    public getCardVerificationsRequest(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: string,
        platform: qPlatform,
        requestBody: GetCardVerificationsRequestBody,
    ): CancelablePromise<GetCardVerificationsResponseBody> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/dispatch/GetCardVerificationsRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * BeginPaymentMethodVerificationRequest
     * If order is marked as needs verification, you need to initiate payment method verification using this call.
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @returns BeginPaymentMethodVerificationResponseBody BeginPaymentMethodVerificationResponse
     * @throws ApiError
     */
    public beginPaymentMethodVerification(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: string,
        platform: qPlatform,
        requestBody: BeginPaymentMethodVerificationRequestBody,
    ): CancelablePromise<BeginPaymentMethodVerificationResponseBody> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/dispatch/BeginPaymentMethodVerificationRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * GetLiveChatSettingsRequest
     * Get live chat settings request
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns GetLiveChatSettingsResponseBody Get live chat skip
     * @throws ApiError
     */
    public getLiveChatSettingsRequest(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: string,
        platform: qPlatform,
        requestBody: GetLiveChatSettingsRequestBody,
        xChk?: string,
    ): CancelablePromise<GetLiveChatSettingsResponseBody> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/dispatch/GetLiveChatSettingsRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * SaveChatEventRequest
     * Chat event
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns SaveChatEventResponseBody Get live chat skip
     * @throws ApiError
     */
    public saveChatEventRequest(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: string,
        platform: qPlatform,
        requestBody: SaveChatEventRequestBody,
        xChk?: string,
    ): CancelablePromise<SaveChatEventResponseBody> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/dispatch/SaveChatEventRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * GetSkipOtpCheckRequest
     * Get skip otp check request
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns GetSkipOtpCheckResponseBody Get skip otp check
     * @throws ApiError
     */
    public getSkipOtpCheckRequest(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: string,
        platform: qPlatform,
        requestBody: GetSkipOtpCheckRequestBody,
        xChk?: string,
    ): CancelablePromise<GetSkipOtpCheckResponseBody> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/dispatch/GetSkipOtpCheckRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * GetFraudInboxNotificationsRequest
     * Get fraud inbox notifications
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns GetFraudInboxNotificationsResponseBody Fraud inbox notifications
     * @throws ApiError
     */
    public getFraudInboxNotifications(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: string,
        platform: qPlatform,
        requestBody: GetFraudInboxNotificationsRequestBody,
        xChk?: string,
    ): CancelablePromise<GetFraudInboxNotificationsResponseBody> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/dispatch/GetFraudInboxNotificationsRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * UpdateFraudInboxNotificationRequest
     * FE should call this method to update status of notification returned in [Get inbox notifications](#operation/getFraudInboxNotifications).
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns UpdateFraudInboxNotificationResponseBody Fraud inbox notifications updating
     * @throws ApiError
     */
    public updateFraudInboxNotification(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: string,
        platform: qPlatform,
        requestBody: UpdateFraudInboxNotificationRequestBody,
        xChk?: string,
    ): CancelablePromise<UpdateFraudInboxNotificationResponseBody> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/dispatch/UpdateFraudInboxNotificationRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
