import type { AnyAction } from '@reduxjs/toolkit'
import {
  redeemAvailablePaymentProvidersRequestActionName,
  redeemCloseAllDialogsActionName,
  redeemCloseLatestDialogActionName,
  redeemOpenDialogActionName,
  redeemOpenSnackbarActionName,
  redeemReplaceDialogActionName,
  redeemSetDataToLocalStorageActionName,
  redeemTrackGAEventActionName,
  redeemSentryCaptureMessageActionName,
  redeemSentryCaptureExceptionActionName,
} from '@patrianna-payments/shared-store/redeem/store/middleware-actions'
import type { ActionTypeMapFnRedeem } from '@patrianna-payments/shared-store/redeem'
import type { MiddlewareType } from 'store/middlewares/types'
import { openSnackbar } from 'store/modules/snackbar/actions'
import { setDataToLocalStorage } from 'utils/localStorage'
import { closeAllDialogs, closeLatestDialog, openDialog, replaceDialog } from '../modules/dialog/actions'
import { redeemTrackGAEvent } from 'store/modules/redeem/actions'
import { handleSentryCaptureException, handleSentryCaptureMessage } from './handlers/sentry'
import { getAvailablePaymentProvidersRequest } from '@patrianna-payments/shared-store/payments/store/actions'
import type { TypedThunk } from '..'

const actionsMap: Record<string, ActionTypeMapFnRedeem<TypedThunk>> = {
  [redeemSetDataToLocalStorageActionName]: setDataToLocalStorage,
  [redeemReplaceDialogActionName]: replaceDialog,
  [redeemOpenSnackbarActionName]: openSnackbar,
  [redeemOpenDialogActionName]: openDialog,
  [redeemCloseLatestDialogActionName]: closeLatestDialog,
  [redeemCloseAllDialogsActionName]: closeAllDialogs,
  [redeemAvailablePaymentProvidersRequestActionName]:
    getAvailablePaymentProvidersRequest as unknown as ActionTypeMapFnRedeem<TypedThunk>, // TODO need fix ActionType
  [redeemTrackGAEventActionName]: redeemTrackGAEvent,
  [redeemSentryCaptureMessageActionName]: handleSentryCaptureMessage,
  [redeemSentryCaptureExceptionActionName]: handleSentryCaptureException,
}

export const redeemMiddleware: MiddlewareType =
  ({ dispatch }) =>
  (next) =>
  (action: AnyAction) => {
    if (action.type in actionsMap) {
      dispatch(actionsMap[action.type](action?.payload))
    }

    next(action)
  }
