/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $PrizeTablePrizeItemData = {
    properties: {
        gcAmount: {
            type: 'number',
        },
        scAmount: {
            type: 'number',
        },
        freeSpinsPrizeInfo: {
            type: 'FreeSpinsPrizeInfo',
        },
        purchaseOfferInfo: {
            type: 'PurchaseOfferInfo',
        },
    },
} as const;
