import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { AppliedShopOffer, PurchaseLimits } from '@patrianna/shared-patrianna-types/store/ShopModule'
import type { OfferInfo } from '@patrianna-payments/shared-store/payments/types/api/CreateOrderResponse'
import type { RewardTemplatesMap, ShopState } from 'store/types/ShopModule'

const initialState: ShopState = {
  offers: [],
  applied: [],
  lastPurchasedOffer: null,
  limits: null,
  offersAfterPurchase: [],
  activeGameRoute: '',
  isShowOfferAfterSuccessModal: true,
  rewardTemplates: {},
}

export const shopSlice = createSlice({
  name: 'shop',
  initialState,
  reducers: {
    setActivatedGame: (state, action: PayloadAction<{ activeGameRoute: string }>) => {
      state.activeGameRoute = action.payload.activeGameRoute
    },
    addOffersAction: (state, action: PayloadAction<{ offers: Array<OfferInfo> }>) => {
      state.offers = action.payload.offers
    },

    addAppliedOffers: (state, action: PayloadAction<{ appliedOffers: Array<Partial<AppliedShopOffer>> }>) => {
      state.applied = action.payload.appliedOffers
    },

    addLastPurchasedOffer: (state, action: PayloadAction<{ lastPurchasedOffer: OfferInfo }>) => {
      state.lastPurchasedOffer = action.payload.lastPurchasedOffer
    },

    setPurchaseLimits: (state, action: PayloadAction<{ limits: PurchaseLimits | null }>) => {
      state.limits = action.payload.limits
    },

    setOffersAfterPurchase: (state, action: PayloadAction<{ offersAfterPurchase: Array<OfferInfo> }>) => {
      state.offersAfterPurchase = action.payload.offersAfterPurchase
    },

    setRewardTemplates: (state, action: PayloadAction<{ rewardTemplates: RewardTemplatesMap }>) => {
      state.rewardTemplates = action.payload.rewardTemplates
    },

    showOffersAfterSuccessModal: (state, action: PayloadAction<boolean>) => {
      state.isShowOfferAfterSuccessModal = action.payload
    },

    clearProducts: () => initialState,
  },
})

export type ActionsTypes = ReturnType<InferValueTypes<typeof shopSlice.actions>>
export const { actions } = shopSlice
