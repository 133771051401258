/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $MiniGameRewardsInfo = {
    properties: {
        code: {
            type: 'string',
        },
        status: {
            type: 'string',
        },
        displayName: {
            type: 'string',
        },
        shortDisplayName: {
            type: 'string',
        },
        iconSmallClosed: {
            type: 'string',
        },
        iconLargeClosed: {
            type: 'string',
        },
        iconSmallOpen: {
            type: 'string',
        },
        iconLargeOpen: {
            type: 'string',
        },
        shopIconSmall: {
            type: 'string',
        },
        shopIconLarge: {
            type: 'string',
        },
        timeLimited: {
            type: 'boolean',
        },
        termUrl: {
            type: 'string',
        },
        displayTagline: {
            type: 'string',
        },
        placementTypes: {
            type: 'array',
            contains: {
                type: 'PlacementType',
            },
        },
        gameType: {
            type: 'GameType',
        },
        prizeTable: {
            type: 'PrizeTable',
        },
        winningItemIndex: {
            type: 'number',
            format: 'int64',
        },
    },
} as const;
