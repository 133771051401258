import type { PropsWithChildren } from 'react'

import classes from './styles.module.scss'

export default function SpecialShopTileLabel({ children }: PropsWithChildren) {
  return (
    <div className={classes.root}>
      <span className={classes.text}>{children}</span>

      <svg width='73' height='67' viewBox='0 0 73 67' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g filter='url(#filter0_d_11394_4717)'>
          <path
            d='M12 1.00007C17 1.00007 17.9 0.989746 17.9 0.989746H68.8508L36.0877 29.4926L4 58.674V15C4 15 4 15 4 9C4 3 7 1.00007 12 1.00007Z'
            fill='#ABF504'
          />
        </g>
        <defs>
          <filter
            id='filter0_d_11394_4717'
            x='0'
            y='0.989746'
            width='72.8506'
            height='65.6841'
            filterUnits='userSpaceOnUse'
            color-interpolation-filters='sRGB'
          >
            <feFlood flood-opacity='0' result='BackgroundImageFix' />
            <feColorMatrix
              in='SourceAlpha'
              type='matrix'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
              result='hardAlpha'
            />
            <feOffset dy='4' />
            <feGaussianBlur stdDeviation='2' />
            <feComposite in2='hardAlpha' operator='out' />
            <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0' />
            <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_11394_4717' />
            <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_11394_4717' result='shape' />
          </filter>
          <linearGradient
            id='paint0_linear_11394_4717'
            x1='36.4254'
            y1='0.989746'
            x2='36.4254'
            y2='58.674'
            gradientUnits='userSpaceOnUse'
          >
            <stop stop-color='#95D700' />
            <stop offset='1' stop-color='#7FB800' />
          </linearGradient>
        </defs>
      </svg>
    </div>
  )
}
