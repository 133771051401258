import { federation } from '@patrianna-payments/payment-remote-entry'
import RemoteEntryPayment from '.'
import React from 'react'
import ReactDOM from 'react-dom'

federation({
  name: 'host',
  shared: {
    react: {
      version: '18.2.0',
      scope: 'default',
      lib: () => React,
      shareConfig: {
        singleton: true,
        requiredVersion: '18.2.0',
      },
    },
    'react-dom': {
      version: '18.2.0',
      scope: 'default',
      lib: () => ReactDOM,
      shareConfig: {
        singleton: true,
        requiredVersion: '18.2.0',
      },
    },
  },
  remotes: [
    {
      name: 'payment',
      entry: `${process.env.PAYMENT_MF_HOST}/mf-manifest.json`,
    },
  ],
  SHARED_STRATEGY: 'loaded-first',
  // PRELOADED_COMPONENTS: ['Redeem', 'SoftKycSubscription', 'RedeemEvents'],
})

function EmptyLoader() {
  return <></>
}

const RemoteEntryPaymentGlobal = () => {
  return (
    <>
      <RemoteEntryPayment module='SoftKycSubscription' CustomLoader={EmptyLoader} />
      <RemoteEntryPayment module='RedeemEvents' CustomLoader={EmptyLoader} />
    </>
  )
}

export default RemoteEntryPaymentGlobal
