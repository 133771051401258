/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $MissionInfo = {
    properties: {
        id: {
            type: 'string',
            isRequired: true,
        },
        completed: {
            type: 'boolean',
            isRequired: true,
        },
        missionEnd: {
            type: 'string',
            format: 'date-time',
        },
        steps: {
            type: 'array',
            contains: {
                type: 'MissionStepInfo',
            },
            isRequired: true,
        },
        rewards: {
            type: 'array',
            contains: {
                type: 'MissionRewardInfo',
            },
        },
    },
} as const;
