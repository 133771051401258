/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $SetAccountInfoRequest = {
    properties: {
        username: {
            type: 'string',
            pattern: '^[a-zA-Z0-9._-]{5,25}$',
        },
        firstName: {
            type: 'string',
        },
        lastName: {
            type: 'string',
        },
        realEmail: {
            type: 'string',
        },
        timezone: {
            type: 'string',
        },
        lang: {
            type: 'string',
        },
        profilePhoto: {
            type: 'string',
        },
        birthDate: {
            type: 'string',
            format: 'date',
        },
        country: {
            type: 'string',
        },
        state: {
            type: 'string',
        },
        city: {
            type: 'string',
        },
        zip: {
            type: 'string',
            pattern: '^[\\d-a-zA-Z ]{3,12}$',
        },
        address: {
            type: 'string',
        },
        address2: {
            type: 'string',
        },
        session: {
            type: 'string',
        },
        doNotSendEmails: {
            type: 'boolean',
        },
        doNotSendPushes: {
            type: 'boolean',
        },
        doNotSendSms: {
            type: 'boolean',
        },
        customerId: {
            type: 'string',
        },
        locale: {
            type: 'string',
        },
        bucket: {
            type: 'string',
            description: `To store customer unspecified information, external id, picture id, etc. Garbage in, garbage out.`,
        },
    },
} as const;
