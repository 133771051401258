import(/* webpackMode: "eager" */ "/vercel/path0/app/[locale]/chatbot.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[locale]/components/CssTransitionsPreloader.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[locale]/components/PreloadResources.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[locale]/components/Providers.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[locale]/components/RootLayout/RootLayout.client.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@patrianna/core-components/src/components/VercelInsightsComponent/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"app/[locale]/layout.tsx\",\"import\":\"Ubuntu\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"300\",\"400\",\"500\",\"700\"],\"display\":\"swap\",\"style\":[\"normal\"],\"fallback\":[\"Helvetica\",\"Arial\",\"sans-serif\"]}],\"variableName\":\"font\"}");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[locale]/nprogress.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/app/[locale]/zendeskMessaging.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/src/styles/components.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/src/styles/global-error-page.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/src/containers/Layouts/RootLayoutSkeleton/styles.module.scss")