import { BottomNavigation, BottomNavigationAction } from '@patrianna/core-components'
import { yieldToMain } from '@patrianna/shared-utils'
import { useAuth } from 'app/context/auth'
import { useRouter } from 'app/context/navigation'
import cx from 'classnames'
import Button from 'components/Button'
import GoldCoinGenerator from 'components/GoldCoinGeneratorSection'
import mobileBottomNavigation from 'config/mobileBottomNavigation'
import CashBalance from 'containers/CashBalance'
import GoldCurrency from 'containers/Layouts/Layout/Header/components/LoggedIn/GoldCurrency'
import SweepstakeCurrency from 'containers/Layouts/Layout/Header/components/LoggedIn/SweepstakeCurrency'
import LoggedOut from 'containers/Layouts/Layout/Header/components/LoggedOut'
import { useTranslation } from 'context/translationContext'
import useIsAuthPage from 'hooks/useIsAuthPage'
import useOtpConfirmRequired from 'hooks/useOtpConfirmRequired'
import { usePathname } from 'next/navigation'
import type { MouseEvent } from 'react'
import { useMemo } from 'react'
import BottomTabsBar from 'src/components/BottomTabsBar'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { openDialog, toggleMenu } from 'store/modules/dialog/actions'
import { getIsMenuOpenedSelector } from 'store/modules/dialog/selectors'
import { openShopHandler } from 'store/modules/shop/actions'
import { shouldForceVerifyKYCSelector } from 'store/modules/user/selectors'
import ROUTES from 'temp/routes.json'
import { getLocationForEventsByPathname } from 'utils/analytics'
import { isSearchPage, isShopPage } from 'utils/route'
import More from './components/More'
import classes from './styles.module.scss'

const goldCoinGeneratorButtonProps = {
  dataTest: 'common-header-buy-button',
  className: classes.shopLink,
}

export default function MenuMobile({
  availableCategoriesLinks,
}: {
  availableCategoriesLinks: Record<string, string>[]
}) {
  const { push } = useRouter()
  const pathname = usePathname()
  const dispatch = useAppDispatch()
  const isOpen = useAppSelector(getIsMenuOpenedSelector)
  const { isLoggedIn } = useAuth()
  const isOtpConfirmRequired = useOtpConfirmRequired()
  const t = useTranslation()
  const isForceVerifyKYC = useAppSelector(shouldForceVerifyKYCSelector)

  const mobileMenuLinks = mobileBottomNavigation()

  const isAuthPage = useIsAuthPage()
  const locationForEvent = useMemo(() => getLocationForEventsByPathname(pathname), [pathname])

  const handleGetCoins = async () => {
    if (isForceVerifyKYC) {
      return
    }

    if (isOtpConfirmRequired) {
      dispatch(openDialog({ modalName: 'PHONE_VERIFICATION_DIALOG' }))
    } else {
      dispatch(openShopHandler({ isMobile: true, location: locationForEvent, routerPush: push }))
    }

    if (isOpen) {
      dispatch(toggleMenu(false))
      await yieldToMain()
    }
  }

  const handleSearch = () => {
    push(ROUTES.SEARCH)

    if (isOpen) {
      dispatch(toggleMenu(false))
    }
  }

  const onChangeHandler = (e: MouseEvent, newValue: number) => {
    // get the first link route array
    const onClick = mobileMenuLinks[newValue]?.onClick

    if (onClick) {
      onClick()
    }
  }

  return (
    <div className={cx(classes.root)} color='default'>
      <BottomTabsBar />
      <div className={cx({ [classes.disabled]: isForceVerifyKYC })}>
        {!isAuthPage && (
          <BottomNavigation onChange={onChangeHandler} className={classes.bottomNavigation}>
            {isLoggedIn ? (
              <div className={classes.cashShop}>
                <div className={classes.cash}>
                  <CashBalance
                    hideMoreInfo
                    dataAttribute='header'
                    mode='headerMode'
                    GoldCurrencyComponent={GoldCurrency}
                    SweepstakeCurrencyComponent={SweepstakeCurrency}
                  />
                </div>
                {!isShopPage(pathname) && (
                  <GoldCoinGenerator buttonProps={goldCoinGeneratorButtonProps}>
                    <Button
                      data-test='common-header-buy-button'
                      onClick={handleGetCoins}
                      variant='contained'
                      className={classes.shopLink}
                    >
                      {t('common.get_coins')}
                    </Button>
                  </GoldCoinGenerator>
                )}
              </div>
            ) : (
              <LoggedOut />
            )}
            <div className={classes.menuActions}>
              {isSearchPage(pathname) ? (
                <BottomNavigationAction
                  icon={<img className={classes.image} src={`${process.env.IMG_ORIGIN}/menu/close.png`} alt='Close' />}
                  onClick={() => push(ROUTES.HOME)}
                  className={cx(classes.navigationButton)}
                />
              ) : (
                <BottomNavigationAction
                  icon={
                    <img className={classes.image} src={`${process.env.IMG_ORIGIN}/menu/search.png`} alt='Search' />
                  }
                  className={cx(classes.navigationButton)}
                  onClick={handleSearch}
                />
              )}
              <More route={pathname} availableCategoriesLinks={availableCategoriesLinks} />
            </div>
          </BottomNavigation>
        )}
      </div>
    </div>
  )
}
