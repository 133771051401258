/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $AccountSessionLimit = {
    properties: {
        startAt: {
            type: 'string',
            isRequired: true,
            format: 'date-time',
        },
        endAt: {
            type: 'string',
            format: 'date-time',
        },
        durationHours: {
            type: 'number',
            isRequired: true,
            format: 'int32',
        },
    },
} as const;
