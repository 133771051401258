/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $AccountSessionLimitsInfo = {
    properties: {
        active: {
            type: 'AccountSessionLimit',
            isRequired: true,
        },
        pending: {
            type: 'AccountSessionLimit',
        },
        pendingUpdate: {
            type: 'boolean',
        },
        pendingRemove: {
            type: 'boolean',
        },
    },
} as const;
