import Button from 'components/Button'
import classes from './DialogEmptyContent.module.scss'
import { useClickHandler } from './hooks/useClickHandler'
import { useTranslation } from 'app/context/translations'

export default function DialogEmptyContent() {
  const t = useTranslation()
  const handleBtnClick = useClickHandler()

  return (
    <div className={classes.dialogEmptyContent}>
      <img src={`${process.env.IMG_ORIGIN}/dialogs/reserve.svg`} height='48' width='48' alt='menu reward empty' />

      <p className={classes.dialogEmptyContentText}>{t('myRewards.empty_text')}</p>

      <hr className={classes.divider} />

      <Button className={classes.dialogEmptyContentButton} onClick={handleBtnClick}>
        {t('myRewards.empty_btn_label')}
      </Button>
    </div>
  )
}
