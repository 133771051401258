/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetPurchaseLimitsRequestBody } from '../models/GetPurchaseLimitsRequestBody';
import type { GetPurchaseLimitsResponseBody } from '../models/GetPurchaseLimitsResponseBody';
import type { GetTransactionLimitRequestBody } from '../models/GetTransactionLimitRequestBody';
import type { GetTransactionLimitResponseBody } from '../models/GetTransactionLimitResponseBody';
import type { ResetPurchaseLimitsRequestBody } from '../models/ResetPurchaseLimitsRequestBody';
import type { ResetPurchaseLimitsResponseBody } from '../models/ResetPurchaseLimitsResponseBody';
import type { SetPurchaseLimitRequestBody } from '../models/SetPurchaseLimitRequestBody';
import type { SetPurchaseLimitResponseBody } from '../models/SetPurchaseLimitResponseBody';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class LimitService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get Transaction limits.
     * Returns transaction limits for a given operation (deposit or redeem).
     * @param xMessageId UUID of original request
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @returns GetTransactionLimitResponseBody GetTransactionLimitResponse
     * @throws ApiError
     */
    public getTransactionLimitRequest(
        xMessageId: string,
        xTimestamp: number,
        brandName: string,
        platform: 'web' | 'android' | 'ios',
        requestBody: GetTransactionLimitRequestBody,
    ): CancelablePromise<GetTransactionLimitResponseBody> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/dispatch/GetTransactionLimitRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Set Purchase limits.
     * Set deposit limits for a user (deposit).
     * @param xMessageId UUID of original request
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @returns SetPurchaseLimitResponseBody SetPurchaseLimitResponse
     * @throws ApiError
     */
    public setPurchaseLimitRequest(
        xMessageId: string,
        xTimestamp: number,
        brandName: string,
        platform: 'web' | 'android' | 'ios',
        requestBody: SetPurchaseLimitRequestBody,
    ): CancelablePromise<SetPurchaseLimitResponseBody> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/dispatch/SetPurchaseLimitRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Returns Purchase limits.
     * Return deposit limits for a user (deposit).
     * @param xMessageId UUID of original request
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @returns GetPurchaseLimitsResponseBody GetPurchaseLimitsResponse
     * @throws ApiError
     */
    public getPurchaseLimitRequest(
        xMessageId: string,
        xTimestamp: number,
        brandName: string,
        platform: 'web' | 'android' | 'ios',
        requestBody: GetPurchaseLimitsRequestBody,
    ): CancelablePromise<GetPurchaseLimitsResponseBody> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/dispatch/GetPurchaseLimitsRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Reset Purchase limits.
     * Reset deposit limits for a user (deposit).
     * @param xMessageId UUID of original request
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @returns ResetPurchaseLimitsResponseBody GetPurchaseLimitsResponse
     * @throws ApiError
     */
    public resetPurchaseLimitRequest(
        xMessageId: string,
        xTimestamp: number,
        brandName: string,
        platform: 'web' | 'android' | 'ios',
        requestBody: ResetPurchaseLimitsRequestBody,
    ): CancelablePromise<ResetPurchaseLimitsResponseBody> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/dispatch/ResetPurchaseLimitsRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
