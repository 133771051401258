import { useTranslation } from 'app/context/translations'
import cx from 'classnames'

import Button from 'components/Button'
import { useAppDispatch } from 'store/hooks'
import { logOut } from 'store/modules/auth/actions'

import classes from './styles.module.scss'

type Props = {
  size?: 'small' | 'large'
  className?: string
  label?: string
  onClick?: () => void
}

export const LogoutButton = ({ className, onClick, label, size = 'small' }: Props) => {
  const dispatch = useAppDispatch()
  const t = useTranslation()
  const onLogOut = () => dispatch(logOut())

  return (
    <Button size={size} onClick={onClick || onLogOut} className={cx(classes.logoutBtn, className)}>
      {label || t('common.logout_capitalize')}
    </Button>
  )
}
