import type { OfferInfo } from '@patrianna-payments/shared-store/payments/types/api/CreateOrderResponse'
import { buyShopOffer } from '@patrianna-payments/shared-store/payments/store/middleware-actions'
import { useRouter } from 'next/navigation'
import { useCallback } from 'react'

import { useAppDispatch } from 'store/hooks'

export const useReturnToCashier = () => {
  const dispatch = useAppDispatch()
  const { push } = useRouter()

  return useCallback(
    (offerCode: string) => {
      dispatch(buyShopOffer({ offer: { code: offerCode } as OfferInfo, routerPush: push }))
    },
    [push]
  )
}
