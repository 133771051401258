import type { PropsWithChildren } from 'react'
import classes from './OpenDrawerIcon.module.scss'
import { useAppSelector } from 'store/hooks'
import { getUnreadPromotionsAmount, isUnvisitedPromotionsIconDismissed } from 'store/modules/promotions'
import { getFreeSpinsSelector } from 'store/modules/freeSpins/selectors'
import cx from 'classnames'

export default function OpenDrawerIcon({ children }: PropsWithChildren) {
  const unvisitedPromotionsIconDismissed = useAppSelector(isUnvisitedPromotionsIconDismissed)
  const freeSpins = useAppSelector(getFreeSpinsSelector)
  const unreadPromotionsAmount = useAppSelector(getUnreadPromotionsAmount)

  const isPromo = unreadPromotionsAmount > 0 && !unvisitedPromotionsIconDismissed
  const isFreeSpins = freeSpins.length > 0
  const isSomeNew = isPromo || isFreeSpins

  return (
    <div
      className={cx({
        [classes.badge]: isSomeNew,
      })}
    >
      {children}
    </div>
  )
}
