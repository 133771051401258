/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $Influencer = {
    properties: {
        accountId: {
            type: 'number',
            format: 'int64',
        },
        nickname: {
            type: 'string',
        },
        active: {
            type: 'boolean',
        },
        numOfGiveawaysDailyLimit: {
            type: 'Limit',
        },
        rewardInUsdThisWeekLimit: {
            type: 'Limit',
        },
        permissions: {
            type: 'Permissions',
        },
        coinGiveawayPrizeConfigs: {
            type: 'array',
            contains: {
                type: 'CoinGiveawayPrizeConfig',
            },
        },
        freeSpinGiveawayPrizeConfigs: {
            type: 'array',
            contains: {
                type: 'FreeSpinGiveawayPrizeConfig',
            },
        },
    },
} as const;
