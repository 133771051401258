/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $PrizeTableItem = {
    properties: {
        code: {
            type: 'string',
        },
        name: {
            type: 'string',
        },
        rank: {
            type: 'number',
            format: 'int32',
        },
        probability: {
            type: 'number',
        },
        prizes: {
            type: 'array',
            contains: {
                type: 'PrizeTablePrizeItem',
            },
        },
    },
} as const;
