import LogoLink from 'containers/LogoLink'
import MenuItems from 'containers/Menu/MenuItems'
import classes from './MenuDesktopSkeleton.module.scss'
import MenuFooter from '../MenuFooter'
import { usePathname } from 'next/navigation'
import ROUTES from 'temp/routes.json'
import useGetGAEventMenuParams from 'containers/Menu/utilityHook'
import { useAuth } from 'app/context/auth'
import MenuDesktopSkeleton from './MenuDesktopSkeleton'
import { useAppSelector } from '@patrianna-payments/shared-store/redeem/store/hooks'
import { isAppInitializedSelector } from 'src/store/modules/appConfig/selectors'
import { getIsUserGCModeSelector } from 'store/modules/user/selectors'

function MenuDesktop({ availableCategoriesLinks }: { availableCategoriesLinks: Record<string, string>[] }) {
  const { isLoggedIn } = useAuth()
  const pathname = usePathname()
  const { trackGAEventsInMenu } = useGetGAEventMenuParams()
  const appInitialized = useAppSelector(isAppInitializedSelector)
  const isGCUser = useAppSelector(getIsUserGCModeSelector)

  const onLogoClickHandler = () => {
    trackGAEventsInMenu(pathname, ROUTES.HOME, 'clicked_on_logo-to-home')
  }

  return (
    <>
      <h1 className={classes.logo}>
        <LogoLink handleOnClick={onLogoClickHandler} />
      </h1>

      {appInitialized ? (
        <>
          <MenuItems isLoggedIn={isLoggedIn} availableCategoriesLinks={availableCategoriesLinks} />
          {/* REENG-595 remove social links for GC user */}
          {!isGCUser && <MenuFooter />}
        </>
      ) : (
        <MenuDesktopSkeleton />
      )}
    </>
  )
}

export default MenuDesktop
