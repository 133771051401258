/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $AccountSessionRestriction = {
    properties: {
        type: {
            type: 'Enum',
            isRequired: true,
        },
        startAt: {
            type: 'string',
            isRequired: true,
            format: 'date-time',
        },
        endAt: {
            type: 'string',
            isRequired: true,
            format: 'date-time',
        },
        reason: {
            type: 'string',
        },
    },
} as const;
