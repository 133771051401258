import { parseDialogParamsFromQuery } from '@patrianna/shared-utils'
import { usePathname, useSearchParams } from 'next/navigation'
import { useEffect } from 'react'

import dialogs from 'dialogs'
import { useAppDispatch } from 'store/hooks'
import { openDialog } from 'store/modules/dialog/actions'
import type { DialogNames } from 'store/types'
import { removeQueryByKeys } from 'utils/searchParams'

export default function useOpenDialogByQuery() {
  const pathname = usePathname()
  const query = useSearchParams()
  const dispatch = useAppDispatch()

  useEffect(() => {
    const { dialogName, isReplaceDisable, ...dialogProps } = parseDialogParamsFromQuery(query)

    if (dialogName && Object.prototype.hasOwnProperty.call(dialogs, dialogName.toString())) {
      if (!isReplaceDisable) {
        const search = removeQueryByKeys({
          query,
          wholeNameKeys: ['dialogName', 'isReplaceDisable'],
          partNameKeys: ['dialogProps.'],
        })

        window.history.replaceState({}, document.title, `${pathname}${search}`)
      }

      dispatch(openDialog({ modalName: dialogName as DialogNames, dialogProps: dialogProps }))
    }
  }, [pathname, query])
}
