/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $MissionStepUpdateRequest = {
    properties: {
        missionId: {
            type: 'string',
            isRequired: true,
        },
        stepCode: {
            type: 'string',
            isRequired: true,
        },
        achieved: {
            type: 'boolean',
            isRequired: true,
        },
    },
} as const;
