/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Giveaway } from './Giveaway';
export type FinalizeGiveawayResponse = {
    giveaway?: Giveaway;
    rejectionReason?: FinalizeGiveawayResponse.rejectionReason;
};
export namespace FinalizeGiveawayResponse {
    export enum rejectionReason {
        NOT_AN_OWNER = 'NOT_AN_OWNER',
        GIVEAWAY_ALREADY_CANCELED_OR_CLOSED = 'GIVEAWAY_ALREADY_CANCELED_OR_CLOSED',
        UNRECOGNIZED = 'UNRECOGNIZED',
    }
}

